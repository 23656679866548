import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { AdminAudit, AdminAuditActionGroups } from '@common';
import {
  AdminAuditListActionType,
  AdminAuditListActionColumn
} from './admin-audit-list-table-column';
import {
  actionColumnsMap,
  displayedColumnsMap
} from './ehs-admin-audit-list-constants';
import { MatChipListboxChange } from '@angular/material/chips';

@Component({
  selector: 'ehs-admin-audit-list',
  templateUrl: './ehs-admin-audit-list.component.html',
  styleUrls: ['./ehs-admin-audit-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsAdminAuditListComponent {
  @Input() adminAudits: AdminAudit[];

  @Input() auditType: AdminAuditActionGroups | 'all';

  @Input() hasNext: boolean;

  @Input() pageNumber: number;

  @Input() currentPageNumber: number;

  @Input() loading: boolean;

  @Output() actionChange = new EventEmitter<{
    actionType: AdminAuditListActionType;
    adminAudit: AdminAudit;
  }>();

  @Output() typeChange = new EventEmitter<{
    type: AdminAuditActionGroups | 'all';
  }>();

  @Output() loadMore = new EventEmitter();

  @Output() changeRenderedPage = new EventEmitter<{
    currentPageNumber: number;
  }>();

  public displayedColumns = displayedColumnsMap.all;

  public trackByFn(index: number) {
    return index;
  }

  public changePageNumber(currentPageNumber: number) {
    this.changeRenderedPage.emit({ currentPageNumber });
  }

  /**
   * Returns list of actions for menu attached to
   * a corresponding type of admin audit
   */
  public getAdminAuditSpecificActions(
    adminAudit: AdminAudit
  ): AdminAuditListActionColumn[] {
    return actionColumnsMap[adminAudit.action];
  }

  public onTypeChange(change: MatChipListboxChange) {
    this.displayedColumns =
      displayedColumnsMap[change.value as AdminAuditActionGroups | 'all'];
    this.typeChange.emit({ type: change.value as AdminAuditActionGroups });
  }

  public onNext() {
    if (this.pageNumber - this.currentPageNumber === 0) {
      this.loadMore.emit();

      return;
    }

    this.changeRenderedPage.emit({
      currentPageNumber: this.currentPageNumber + 1
    });
  }
}
