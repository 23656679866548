<div class="ehs-user-table ehs-table-wrapper"
     *ngIf="users?.length">
  <div>
    <mat-progress-bar mode="indeterminate"
                      *ngIf="loading"></mat-progress-bar>
  </div>
  <cdk-virtual-scroll-viewport itemSize="40"
                               minBufferPx="40"
                               maxBufferPx="500"
                               (scrolledIndexChange)="onScrolledIndexChange($event)"
                               class="viewport">
    <table class="ehs-table sticky-header sticky-last">
      <tr>
        <th *ngFor="let col of columns; let i = index"
            [style.top]="headerTop"
            [style.width]="col.width"
            [style.min-width]="col.width"
            [ngClass]="col.prop">
          {{ col.columnTitle }}
        </th>
        <th style.width="100%"
            *ngIf="!clientAdmin">
          <div fxLayout="row"
               fxLayoutAlign="end">
            <mat-spinner *ngIf="userActions?.length && actionLoading"
                         [diameter]="22"></mat-spinner>
          </div>
        </th>

      </tr>
      <tr *cdkVirtualFor="let user of users">
        <td *ngFor="let col of columns; let i = index"
            [ngClass]="col.prop"
            [style.width]="i === columns.length - 1 ? '100%' : col.width"
            [style.min-width]="i === columns.length - 1 ? '100%' : col.width">
          <ng-container [ngSwitch]="col.prop">

            <ng-container *ngSwitchCase="'user'">
              <div fxLayout="row"
                   fxLayoutAlign="start center"
                   fxLayoutGap="4px">

                <a style="text-decoration: underline; color: #1a237e; align-self: center;"
                   title="View User Page"
                   *ngIf="!clientAdmin"
                   [routerLink]="getViewLink(user)">{{user.firstName }} {{
                  user.lastName }}</a>
                <span *ngIf="clientAdmin">{{user.firstName }} {{
                  user.lastName }}</span>

                <mat-icon *ngIf="user.locked"
                          class="lockIcon"
                          title="Account Locked">
                  lock</mat-icon>
                <mat-icon *ngIf="user.hasMFADisabled"
                          class="lockIcon"
                          title="MFA Disabled">
                  no_cell</mat-icon>

                <div *ngIf="getStatus(user) as status"
                     style="text-align:center;">
                  <mat-icon title="Inactive"
                            [ngClass]="status"
                            *ngIf="status === 'inactive'">hourglass_full</mat-icon>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'partial'">
              <mat-icon [title]="user.partial? 'Walk-in' : 'Registered'"
                        [ngStyle]="{color: user.partial ? '#795548' : '#1b5e20'}">
                {{ user.partial ? 'directions_run' : 'how_to_reg' }}
              </mat-icon>
            </ng-container>



            <ng-container *ngSwitchCase="'company'">
              {{ getCompany(user)?.name || '' }}
            </ng-container>

            <ng-container *ngSwitchCase="'clientAdmin'">
              <mat-icon>{{user.clientAdmin ? 'security' : ''}}</mat-icon>
            </ng-container>

            <ng-container *ngSwitchDefault>
              {{ user[col.prop] }}
            </ng-container>

          </ng-container>
        </td>

        <td *ngIf="userActions?.length && !clientAdmin"
            [style.width]="'80px'"
            [style.min-width]="'80px'">
          <a mat-icon-button
             class="small-icon-button"
             *ngIf="showLoginAsUser"
             type="button"
             color="primary"
             title="Login As User"
             aria-label="Login As User"
             (click)="actionChange.emit({type: adminUserScopes.USER_LOGIN, user})">
            <mat-icon style="font-size: 18px;">login</mat-icon>
          </a>
          <button mat-icon-button
                  class="small-icon-button"
                  type="button"
                  aria-label="Menu"
                  class="small-icon-button"
                  [disabled]="actionLoading"
                  [matMenuTriggerFor]="userActionMenu"
                  [matMenuTriggerData]="{ user: user }"
                  (click)="$event.stopPropagation(); $event.preventDefault()">
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
      </tr>
    </table>
  </cdk-virtual-scroll-viewport>
</div>

<mat-menu #userActionMenu="matMenu">
  <ng-template matMenuContent
               let-user="user">
    <ng-container *ngFor="let action of getUserSpecificActions(user); trackBy: trackByFn">
      <ng-container *ngIf="action.type === 'view'; else showButton">
        <a mat-menu-item
           title="View the user"
           [routerLink]="getViewLink(user)">
          <mat-icon *ngIf="action.icon">{{action.icon}}</mat-icon>
          View
        </a>
      </ng-container>
      <ng-template #showButton>
        <button mat-menu-item
                (click)="actionChange.emit({ type: action.type, user })"
                [title]="action.name"
                type="button">
          <mat-icon *ngIf="action.icon">{{action.icon}}</mat-icon>
          {{action.name}}
        </button>
      </ng-template>
    </ng-container>
  </ng-template>
</mat-menu>
