/* eslint-disable @typescript-eslint/no-unused-vars */
import { LoginErrorResponseTypes, SignUpRequest, User } from '@common';
import { Action, createReducer, on } from '@ngrx/store';
import { DateTime } from 'luxon';
import { authActions } from './auth.actions';

export type TempSignUp = Pick<User, 'firstName' | 'lastName'> & {
  email: string;
  confirmEmail?: string;
  password: string;
  confirmPassword?: string;
};

export interface AuthState {
  /**
   * If we are loading the current user's information.
   * At the start of the application this is true, as
   * we must know if the current user has a session or not.
   */
  loading?: boolean;
  /**
   * The current user of the application. This is loaded from the backend
   * **after** the identity is loaded, which acts as the users actual login.
   */
  user?: User;
  /**
   * The current user of the application. This is loaded from the backend
   * **after** the identity is loaded, which acts as the users actual login, this version of the user includes the clientAdmin properties to check for validation, but doesn't override the user object used in other places in the app.
   */
  clientAdmin?: User;
  /**
   * The type of authentication error returned by the backend
   */
  loginErrorType?: LoginErrorResponseTypes;
  /**
   * Flag that is true if the sign up request got an error about invalid authentication.
   * This wont be set as true if there is a network or unexpected error. Instead
   * the loginFailed action will be emitted, and notification should be shown.
   * TODO: Migrate to something similar to the loginErrorType see #407
   */
  invalidSignUp?: boolean;
  /**
   * The request we sent and was valid during the signUp request.
   * This is passed directly to the verify page, which should clear this
   * data after loading it, so this data should only exist within the state
   * during the transition.
   */
  sentSignUpRequest?: SignUpRequest;

  /**
   * Temporary signUp information being passed as part of the state, so we can populate the signUp section
   * Once used it is cleaned up and discarded.
   */
  tempSignUp?: TempSignUp;

  /**
   * Returns if the given username is valid. Defaults to
   * false initially.
   */
  isValidUsername?: boolean;
  /**
   * If this is loading or not. Only changes
   * when checking username
   */
  isValidUsernameLoading?: boolean;

  /**
   * If sending the verified email is loading or not
   */
  verifyEmailLoading?: boolean;
  /**
   * If re-authentication flow is loading
   */
  reAuthLoading?: boolean;
  /**
   * If the update-password flow is loaded
   */
  updatePasswordLoading?: boolean;
  /**
   * If we are updating the user's sensitive info (password,mfa),
   * used for the guard
   */
  inUpdateSensitiveInfoFlow?: boolean;
  /**
   * If the user requested their password to reset, primarily used to
   * pass thru reset-requested guard.
   */
  inPasswordResetFlow?: boolean;
  /**
   * The date and time as a string represetation for the last
   * time we send the verification email. We only let user's
   * do this every 30 seconds to prevent overload spam.
   *
   * Saved as ISO string using Luxon.
   */
  lastSentVerificationEmail?: string;

  /**
   * If the user has MFA enable,and needs to verify its MFA to login, used to redirect user to the verify-mfa page
   */
  inMFAFlow?: boolean;
  /**
   * If the user has a passkey enrolled
   */
  hasPasskeyEnrolled?: boolean;
}

const reducer = createReducer<AuthState>(
  {},
  on(authActions.getUser, (state: AuthState) => ({ ...state, loading: true })),
  on(authActions.getUserSuccess, (state: AuthState, { user, clientAdmin }) => ({
    ...state,
    loading: false,
    user,
    clientAdmin
  })),
  on(authActions.getUserFailed, (state: AuthState) => ({
    ...state,
    loading: false
  })),

  on(authActions.login, (state: AuthState) => ({ ...state, loading: true })),
  on(authActions.loginSuccess, (state: AuthState, { user, clientAdmin }) => ({
    ...state,
    loading: false,
    user,
    clientAdmin
  })),
  on(authActions.loginFailed, (state: AuthState, { err }) =>
    err && err.type
      ? {
          ...state,
          loginErrorType: err.type,
          loading: false
        }
      : {
          ...state,
          loginErrorType: LoginErrorResponseTypes.INTERNAL_ERROR,
          loading: false
        }
  ),
  on(authActions.firebaseLoginFailed, (state: AuthState) => ({
    ...state,
    loginErrorType: LoginErrorResponseTypes.NOT_AUTHORIZED
  })),
  on(authActions.lockAccount, (state: AuthState) => ({
    ...state,
    loginErrorType: LoginErrorResponseTypes.ACCOUNT_LOCKED
  })),
  on(authActions.lockAccountSuccess, (state: AuthState) => ({
    ...state,
    loading: false
  })),
  on(authActions.lockAccountFailed, (state: AuthState) => ({
    ...state,
    loading: false
  })),
  on(authActions.getIsLocked, (state: AuthState) => ({
    ...state,
    loading: true
  })),
  on(authActions.getIsLockedSuccess, (state: AuthState, { locked }) => ({
    ...state,
    loginErrorType: locked
      ? LoginErrorResponseTypes.ACCOUNT_LOCKED
      : state.loginErrorType,
    loading: false
  })),
  on(authActions.getIsLockedFailed, (state: AuthState) => ({
    ...state,
    loading: false
  })),
  on(
    authActions.clearLoginError,
    ({ loginErrorType, ...state }: AuthState) => ({
      ...state
    })
  ),
  on(authActions.signUp, (state: AuthState) => ({ ...state, loading: true })),
  on(authActions.signUpSuccess, (state: AuthState) => ({
    ...state,
    loading: false
  })),
  on(authActions.signUpFailed, (state: AuthState) => ({
    ...state,
    loading: false
  })),
  on(authActions.tempSignUp, (state: AuthState, { tempSignUp }) => ({
    ...state,
    tempSignUp
  })),
  on(authActions.tempSignUpClear, ({ tempSignUp, ...state }: AuthState) => ({
    ...state
  })),
  on(authActions.logout, (state: AuthState) => ({ ...state, loading: true })),
  on(
    authActions.logoutSuccess,
    ({ user, ...state }: AuthState, { clientCode }) => ({
      ...state,
      clientCode,
      loading: false
    })
  ),
  on(authActions.logoutFailed, (state: AuthState) => ({
    ...state,
    loading: false
  })),
  on(
    authActions.setSentSignUpRequest,
    (state: AuthState, { sentSignUpRequest }) => ({
      ...state,
      sentSignUpRequest
    })
  ),
  on(
    authActions.clearSentSignUpRequest,
    ({ sentSignUpRequest, ...state }: AuthState) => state
  ),
  on(authActions.clear, ({ user, ...state }: AuthState) => ({ ...state })),
  on(authActions.updateUser, (state: AuthState) => ({
    ...state,
    loading: true
  })),
  on(authActions.updateUserSuccess, (state: AuthState, { user }) => ({
    ...state,
    user: {
      ...state.user,
      ...user
    },
    loading: false
  })),
  on(authActions.updateUserFailed, (state: AuthState) => ({
    ...state,
    user: {
      ...state.user
    },
    loading: false
  })),
  on(authActions.isValidUsername, (state: AuthState) => ({
    ...state,
    loading: true,
    isValidUsernameLoading: true
  })),
  on(authActions.isValidUsernameSuccess, (state: AuthState, { valid }) => ({
    ...state,
    loading: false,
    isValidUsername: valid,
    isValidUsernameLoading: false
  })),
  on(authActions.isValidUsernameFailed, (state: AuthState) => ({
    ...state,
    loading: false,
    isValidUsernameLoading: false
  })),
  on(authActions.verifyEmail, ({ loginErrorType, ...state }: AuthState) => ({
    ...state,
    loading: true,
    verifyEmailLoading: true,
    lastSentVerificationEmail: DateTime.fromJSDate(new Date()).toISO()
  })),
  on(authActions.verifyEmailSuccess, (state: AuthState) => ({
    ...state,
    loading: false,
    verifyEmailLoading: false
  })),
  on(authActions.verifyEmailFailed, (state: AuthState) => ({
    ...state,
    loading: false,
    verifyEmailLoading: false
  })),
  on(authActions.forgotUsername, (state: AuthState) => ({
    ...state,
    loading: true
  })),
  on(authActions.forgotUsernameSuccess, (state: AuthState) => ({
    ...state,
    loading: false
  })),
  on(authActions.forgotUsernameFailed, (state: AuthState) => ({
    ...state,
    loading: false
  })),
  on(authActions.resetPassword, (state: AuthState) => ({
    ...state,
    loading: true,
    inPasswordResetFlow: true
  })),
  on(authActions.resetPasswordSuccess, (state: AuthState) => ({
    ...state,
    loading: false
  })),
  on(
    authActions.resetPasswordFailed,
    ({ inPasswordResetFlow, ...state }: AuthState) => ({
      ...state,
      loading: false
    })
  ),
  on(
    authActions.resetPasswordFlowDone,
    ({ inPasswordResetFlow, ...state }: AuthState) => state
  ),
  on(authActions.verifyMFA, (state: AuthState) => ({
    ...state,
    inMFAFlow: true
  })),
  on(authActions.verifyMFASuccess, (state: AuthState) => ({
    ...state,
    inMFAFlow: false
  })),
  on(authActions.reAuth, (state) => ({ ...state, reAuthLoading: true })),
  on(authActions.reAuthSuccess, (state) => ({
    ...state,
    reAuthLoading: false,
    inUpdateSensitiveInfoFlow: true
  })),
  on(authActions.reAuthFailed, (state) => ({ ...state, reAuthLoading: false })),
  on(authActions.updatePassword, (state) => ({
    ...state,
    updatePasswordLoading: true
  })),
  on(
    authActions.updatePasswordSuccess,
    ({ inUpdateSensitiveInfoFlow, ...state }) => ({
      ...state,
      updatePasswordLoading: false
    })
  ),
  on(authActions.updatePasswordFailed, (state) => ({
    ...state,
    updatePasswordLoading: false
  })),
  on(
    authActions.clearInUpdateSensitiveInfoFlow,
    ({ inUpdateSensitiveInfoFlow, ...state }) => state
  ),
  on(authActions.getPasskeyOptions, (state) => state),
  on(authActions.getPasskeyOptionsSuccess, (state) => ({
    ...state
  })),
  on(authActions.getPasskeyOptionsFailed, (state) => state),
  on(authActions.verifyPasskeyRegistration, (state) => state),
  on(authActions.verifyPasskeyRegistrationSuccess, (state) => ({
    ...state,
    hasPasskeyEnrolled: true
  })),
  on(authActions.verifyPasskeyRegistrationFailed, (state) => state),
  on(authActions.hasPasskeyEnrolled, (state) => ({ ...state, loading: true })),
  on(authActions.hasPasskeyEnrolledSuccess, (state, { enrolledPasskey }) => ({
    ...state,
    hasPasskeyEnrolled: enrolledPasskey,
    loading: false
  })),
  on(authActions.hasPasskeyEnrolledFailed, (state) => ({
    ...state,
    loading: false
  })),
  on(authActions.disablePasskey, (state) => state),
  on(authActions.disablePasskeySuccess, (state) => ({
    ...state,
    hasPasskeyEnrolled: false
  })),
  on(authActions.disablePasskeyFailed, (state) => state),
  on(authActions.getPasskeyAuthenticationOptions, (state) => state),
  on(authActions.getPasskeyAuthenticationOptionsSuccess, (state) => state),
  on(authActions.getPasskeyAuthenticationOptionsFailed, (state) => state),
  on(authActions.verifyPasskeyAuthentication, (state: AuthState) => ({
    ...state,
    loading: true
  })),
  on(
    authActions.verifyPasskeyAuthenticationSuccess,
    (state: AuthState, { user, clientAdmin }) => ({
      ...state,
      loading: false,
      user,
      clientAdmin
    })
  ),
  on(
    authActions.verifyPasskeyAuthenticationFailed,
    (state: AuthState, { err }) =>
      err && err.type
        ? {
            ...state,
            loginErrorType: err.type,
            loading: false
          }
        : {
            ...state,
            loginErrorType: LoginErrorResponseTypes.INTERNAL_ERROR,
            loading: false
          }
  )
);

export function AuthReducer(state: AuthState, action: Action) {
  return reducer(state, action);
}
