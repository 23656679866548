import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import {
  EhsNavActionData,
  EhsNavData,
  EhsNavDataNestedId,
  isEhsNavNestedData
} from '../../models/ehs-nav-data';
import {
  isEndEhsNavData,
  isStartEhsNavData
} from '../../utils/ehs-nav-data-utils';
import { User } from '@common';

@Component({
  selector: 'ehs-side-nav',
  templateUrl: './ehs-side-nav.component.html',
  styleUrls: ['./ehs-side-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsSideNavComponent {
  /**
   * The navigation data to display in the side-nav.
   */
  @Input() data?: EhsNavData[];

  @Input() user?: User;

  @Input() showProfileIcons = false;

  @Input() iconSrcMap: Record<string, string> = {};

  /**
   * The list of nav-element ids that we are "expanded".
   * This should include only nested element ids and can be a "global"
   * state if ids are all unique.
   */
  @Input() expandedElements: EhsNavDataNestedId[] = [];

  /**
   * Event emitted when a direct child, or nested child.
   * The parent should handle this state change.
   */
  @Output() expandedChange = new EventEmitter<EhsNavData>();

  /**
   * The action data to emit from the nested data
   */
  @Output() action = new EventEmitter<EhsNavActionData>();

  public get startData(): EhsNavData[] {
    return (this.data || []).filter(isStartEhsNavData);
  }

  public get endData(): EhsNavData[] {
    return (this.data || []).filter(isEndEhsNavData);
  }

  public get hasSplitData(): boolean {
    return !!(this.startData.length && this.endData.length);
  }

  /**
   * If the nested element is expanded, will only return true
   * for expanded nested nave elements. Non-nested elements can't be expanded.
   */
  public isExpanded(data: EhsNavData) {
    return isEhsNavNestedData(data) && this.expandedElements.includes(data.id);
  }
}
