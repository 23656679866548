import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { getId, Notification } from '@common';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'ehs-user-notification-list',
  templateUrl: './ehs-user-notification-list.component.html',
  styleUrls: ['./ehs-user-notification-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsUserNotificationListComponent {
  public readonly displayedColumns = ['unread', 'readAt', 'message', 'creator'];

  /**
   * The list of user-notifications to display within this list component.
   */
  @Input() notifications: Notification[] = [];

  /**
   * If we are to display the checkbox in its "indeterminate" state
   */
  @Input() indeterminate?: boolean;

  /**
   * If the all are toggled
   */
  @Input() allAreSelected?: boolean;
  /**
   * If we are to disable all actions within the table.
   */
  @Input() disabled?: boolean;

  @ViewChild(MatAccordion) accordion: MatAccordion;

  /**
   * Event emitted if we are toggling the top-level select-all
   */
  @Output() toggleAll = new EventEmitter();

  @Output() deleteNotifications = new EventEmitter<string[]>();

  public deleteNotification(notification: Notification) {
    // ToDo: When check boxes are added we will need to pass array of notifications to delete
    this.deleteNotifications.emit([getId(notification)]);
  }
}
