import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  HealthProgramTeam,
  Icons,
  IconType,
  ProfileIcons,
  TeamIcons,
  TeamIconsSrcs,
  User
} from '@common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IconSelectDialogData } from './icon-select-dialog-data';
import { IconImageResizeService } from '../icon-image-resize-dialog/icon-image-resize-dialog.service';

@Component({
  selector: 'ehs-icon-select-dialog',
  templateUrl: './icon-select-dialog.component.html',
  styleUrls: ['./icon-select-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class IconSelectDialogComponent implements OnInit {
  public user: User;
  public team: HealthProgramTeam;
  public type: IconType;
  public selectedIcon: Icons;

  public iconsSrcMap: Record<string, string> = {};
  public iconNames = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: IconSelectDialogData,
    private dialogRef: MatDialogRef<IconSelectDialogComponent>,
    private snackBar: MatSnackBar,
    private imageResizeDialogService: IconImageResizeService
  ) {}

  private profileIconSrcs = {
    [ProfileIcons.SNAKE]: '../../../assets/img/profile-icons/snake.svg',
    [ProfileIcons.ELEPHANT]: '../../../assets/img/profile-icons/elephant.svg',
    [ProfileIcons.PANDA]: '../../../assets/img/profile-icons/panda.svg',
    [ProfileIcons.LUCHADOR]: '../../../assets/img/profile-icons/luchador.svg'
  };

  ngOnInit() {
    this.user = this.data.user;
    this.team = this.data.team;
    this.type = this.data.type;

    this.iconNames =
      this.type === IconType.PROFILE
        ? Object.values(ProfileIcons).filter(
            (icon) => icon !== ProfileIcons.USER
          )
        : Object.values(TeamIcons).filter((icon) => icon !== TeamIcons.TEAM);

    if (this.type !== IconType.PROFILE && this.type !== IconType.TEAM) {
      return;
    }

    this.iconsSrcMap =
      this.type === IconType.PROFILE ? this.profileIconSrcs : TeamIconsSrcs;

    this.selectedIcon =
      this.type === IconType.PROFILE
        ? this.user?.profileIcon
        : this.team?.teamIcon;
  }

  onSubmit() {
    if (!this.selectedIcon) {
      this.snackBar.open('Please select an icon', 'Close', {
        duration: 2000
      });

      return;
    }

    this.dialogRef.close({
      icon: this.selectedIcon
    });
  }

  onImageUpload(event: any) {
    const files = event.target.files as FileList;

    if (files.length > 0) {
      const _file = URL.createObjectURL(files[0]);

      this.imageResizeDialogService
        .open({
          user: this.user,
          team: this.team,
          imageFile: _file
        })
        .subscribe((image) => {
          if (image) {
            this.dialogRef.close({
              icon: ProfileIcons.USER,
              profileImage: image
            });
          }
        });
    }
  }
}
