<h1 mat-dialog-title>
  Change Optional Tests
</h1>

<mat-dialog-content>
  <div class="mat-body-1"
       *ngIf="optionalUserTests">
    <ng-container *ngIf="!optionalUserTests.length">
      <div>
        No optional tests to change
      </div>
    </ng-container>
    <div fxLayout="column"
         fxLayoutAlign="start start"
         fxLayoutGap="16px">
      <div *ngFor="let optionalTest of optionalUserTests">
        <mat-checkbox #optionalTestCheckbox
                      (change)="toggleOptionalTest(optionalTest)"
                      [checked]="isChecked(optionalTest)"
                      color="primary">
          {{getName(optionalTest)}} | {{getPrice(optionalTest) | currency}}
        </mat-checkbox>
      </div>
    </div>
  </div>
  <div class="mat-body-1"
       *ngIf="unavailableOptionalTests?.length">
    <div fxLayout="column"
         fxLayoutAlign="start start"
         fxLayoutGap="16px">
      <div>Unavailable</div>
      <div *ngFor="let optionalTest of unavailableOptionalTests">
        <mat-checkbox #optionalTestCheckbox
                      (change)="toggleOptionalTest(optionalTest)"
                      [checked]="isChecked(optionalTest)"
                      color="primary">
          {{getName(optionalTest)}} | {{getPrice(optionalTest) | currency}}
        </mat-checkbox>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end"
                    fxLayoutGap="16px">
  <button type="button"
          class="grey-cancel"
          mat-button
          [mat-dialog-close]="undefined">
    Cancel
  </button>
  <button type="button"
          color="primary"
          mat-button
          (click)="close()"
          [disabled]="isDisabled"
          cdkFocusInitial>
    Confirm
  </button>
</mat-dialog-actions>