import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import {
  DateUtil,
  deepClone,
  User,
  UserUtil,
  UserWithRegistrations
} from '@common';
import { Subject, debounceTime, takeUntil } from 'rxjs';

// Internal type
type UniqueUser = Pick<User, 'birthDay' | 'ssn' | 'lastName'>;
@Component({
  selector: 'ehs-user-unique-form',
  templateUrl: './ehs-user-unique-form.component.html',
  styleUrls: ['./ehs-user-unique-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  standalone: false
})
export class EhsUserUniqueFormComponent implements OnInit, OnDestroy {
  /**
   * The user we will provide as the default values
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('user') set defaultUser(user: UniqueUser | null) {
    // Clone just in-case there is deep data, as we will change it directly
    this.user = deepClone(
      user || {
        lastName: '',
        ssn: '',
        birthDay: ''
      }
    );
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('usersSearch') usersSearch: (User | UserWithRegistrations)[];

  /**
   * If all fields are required, by default they aren't
   */
  @Input() required?: boolean;

  /**
   * Event emitted if the user changes
   */
  @Output() userChange = new EventEmitter<UniqueUser>();

  /**
   * Event emitter to search user depending on lastName
   */
  @Output() search = new EventEmitter<string>();

  /**
   * The user we will use internally
   */
  public user: UniqueUser;
  public readonly ssnRegExp = UserUtil.ssnRegExp;

  private searchChanged: Subject<string> = new Subject<string>();
  private takeUntil = new Subject<void>();

  ngOnInit(): void {
    this.searchChanged
      .pipe(debounceTime(500), takeUntil(this.takeUntil))
      .subscribe((searchLastName: string) => {
        this.search.emit(searchLastName);
      });
  }

  ngOnDestroy(): void {
    this.takeUntil.next();
    this.takeUntil.unsubscribe();
  }

  public formatDate(value: string, user: UniqueUser, field: NgModel) {
    this.user.birthDay = DateUtil.formatDate(value);
    field.control.updateValueAndValidity();
  }

  public change(user: UniqueUser) {
    this.userChange.emit(deepClone(user));
  }

  public searchUser(searchLastName: string) {
    this.searchChanged.next(searchLastName);
  }

  public selectUser(user: User) {
    const uniqueUser: UniqueUser = {
      lastName: user.lastName,
      ssn: user.ssn,
      birthDay: DateUtil.formatDate(user.birthDay)
    };

    this.userChange.emit(deepClone(uniqueUser));
  }
}
