import { ObjectId } from 'mongodb';
import { Company } from './company';
import { DbDocument } from './db-document';
import { HpnImportAudit, HpnImportAuditId } from './hpn/hpn-import-audit';
import { LocationData } from './location-data';
import { PersonType } from './person-type';
import { Genders, SamlStrategy, User, UserStatus } from './user';

/**
 * This document is not actually saved as is, its just a "simplified"
 * version of the user-schema.
 */
export interface PartialUser extends DbDocument, Partial<LocationData> {
  shortId: string;
  /**
   * If the user is partial. This is always true
   */
  partial: true;
  /**
   * The reference to the import for this user. Used for auditing, and possible rollbacks.
   */
  hpn?: HpnImportAuditId | ObjectId | HpnImportAudit;
  /**
   * 4 digit number of SSN
   * Change to string instead of number to support leading 0s
   */
  ssn: string;
  /**
   * The first name of the user
   */
  firstName: string;
  /**
   * The last name of the user
   */
  lastName: string;
  /**
   * The birthday of the user format should be 'YYYY-MM-DD'
   *
   * **note** on the front-end the format is actually MM/DD/YYYY,
   * this is converted on the BACKEND in the new-user-finger-stick-form-request.
   * This is because the logic to handle it on the client-side
   * would of been more annoying and the format isn't fully checked until
   * it reaches mongoose.
   */
  birthDay: string;
  /**
   * The company this partial user goes to
   */
  company: string | ObjectId | Company;
  /**
   * The gender of the user that doesn't exist
   */
  gender: Genders;
  /**
   * The type of the person signing up, this can be undefined initially
   * but could be defined in the "mass user-creation" mode of
   * the HPN import
   */
  personType?: PersonType;
  /**
   * The user's email, added with HPN import update to support
   * "mass user-creation" mode of HPN import.
   * Still might not be passed by HPN itself, so this is optional.
   */
  email?: string;
  /**
   * The phone number of the user, added with the HPN import update to
   * support "mass user-creation" mode.
   *
   * Not passed by HPN itself, so this is optional
   */
  phone?: string;
  /**
   * Employee ID is provided by some customers to help verify eligibility for those
   * users. This is not required for all customers.
   */
  employeeId?: string;
  /**
   * Relationship ID is set during imports when a user's spouse's information is provided along side the
   * primary user and the spouse already exists in the system.
   */
  relationshipId?: string;
  /**
   * Keep track of the way the user was created, this is just used for troubleshooting
   * so we know if the user signed in using the SAML auth or not.
   */
  samlStrategy?: SamlStrategy;
  /**
   * Microsoft ID is the account ID provided when the user signs in using the Microsoft button. This is
   * a unique ID per customer.
   */
  microsoftId?: string;
  /**
   * Google ID is the account ID provided when the user signs in using the Google button. This is
   * a unique ID per customer.
   */
  googleId?: string;
  /**
   * Custom field for client to pass in additional data
   */
  clientFilter?: string;
  /**
   * The status of the user
   */
  activityStatus?: UserStatus;
  /**
   * The date the users access expires
   */
  temporaryAccessExpires?: Date;
}

/**
 * Type-guard for a partial user to know if this user is a partial user.
 */
export const isPartialUser = (
  user: Partial<User> | Partial<PartialUser>
): user is PartialUser => !!user.partial;
