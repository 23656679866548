import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Company, getId } from '@common';
import { CompanyNgrxFacade } from '@ehs-ngrx/company-ngrx';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import { EhsMoveUserCompanyDialogData } from './ehs-move-user-company-dialog-data';
import { EhsMoveUserCompanyDialogResponse } from './ehs-move-user-company-dialog-response';

@Component({
  selector: 'ehs-move-user-company-dialog',
  templateUrl: './ehs-move-user-company-dialog.component.html',
  styleUrls: ['./ehs-move-user-company-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsMoveUserCompanyDialogComponent {
  public loading$ = this.companyFacade.loading$;
  public user = this.data.user;
  public search$ = new BehaviorSubject<string>('');
  public companyToMoveTo?: string | Company;
  public userCompany$ = this.companyFacade.get$(getId(this.user.company));

  public companies$ = this.companyFacade
    .createSearch$({
      // Limit the output, and force at least 3 characters
      search$: this.search$.pipe(
        debounceTime(100),
        filter((query) => query.length >= 3)
      ),
      keys: ['name']
    })
    .pipe(
      // Only show the first 50.
      map((companies) => companies.slice(0, 50))
    );

  get submitDisabled() {
    return typeof this.companyToMoveTo !== 'object';
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EhsMoveUserCompanyDialogData,
    private dialogRef: MatDialogRef<EhsMoveUserCompanyDialogComponent>,
    private companyFacade: CompanyNgrxFacade
  ) {
    this.companyFacade.list();
  }

  onSubmit() {
    this.dialogRef.close({
      company: this.companyToMoveTo,
      user: this.user
    } as EhsMoveUserCompanyDialogResponse);
  }
}
