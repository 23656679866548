import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  Input,
  EventEmitter,
  Output
} from '@angular/core';
import {
  EventService,
  EventServiceUtil,
  getId,
  HPFUploadFile,
  ProviderHealthService,
  ProviderHealthUploadStatus,
  ServiceType,
  UserRegistration
} from '@common';
import { ProviderHealthUploadsColumn } from './provider-health-uploads-column';

@Component({
  selector: 'ehs-provider-health-uploads-table',
  templateUrl: './ehs-provider-health-uploads-table.component.html',
  styleUrls: ['./ehs-provider-health-uploads-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsProviderHealthUploadsTableComponent {
  @Input() columns: ProviderHealthUploadsColumn[] = [];
  @Input() rows: UserRegistration[] = [];
  @Input() hasNext?: boolean;

  @Output() loadMore = new EventEmitter();
  @Output() rowClicked = new EventEmitter<{
    eventService: string;
    userRegistration: string;
    company: string;
  }>();

  @Output() downloadProviderHealthForm = new EventEmitter<{
    userRegistrationId: string;
    file: HPFUploadFile;
  }>();

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  viewPort: CdkVirtualScrollViewport;

  /**
   * To keep the header positioning:sticky inside cdk viewport
   */
  get headerTop() {
    if (!this.viewPort || !this.viewPort['_renderedContentOffset']) {
      return '-0px';
    }

    const offset = this.viewPort['_renderedContentOffset'];

    return `-${offset}px`;
  }

  trackByFn(index: number) {
    return index;
  }

  public getReturnBy(userRegistration: UserRegistration) {
    const eventService = userRegistration.eventService as EventService;

    if (!eventService) {
      return '';
    }

    const service = EventServiceUtil.getSelectedService({
      eventService,
      serviceType: ServiceType.PROVIDER_HEALTH
    });

    return (service as ProviderHealthService)?.returnBy || '';
  }

  handleDownloadProviderHealthForm(params: {
    userRegistration: UserRegistration;
    file: HPFUploadFile;
  }) {
    const { userRegistration, file } = params;

    this.downloadProviderHealthForm.emit({
      file,
      userRegistrationId: getId(userRegistration)
    });
  }

  public getChipColor(status: string | ProviderHealthUploadStatus) {
    switch (status) {
      case ProviderHealthUploadStatus.COMPLETED:
        return 'green';
      case ProviderHealthUploadStatus.UPLOADED:
        return '#2196f3';

      default:
        return 'red';
    }
  }
}
