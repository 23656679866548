import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  ViewChild,
  EventEmitter
} from '@angular/core';
import { DateUtil, UserIncentive } from '@common';

interface UserIncentiveColumn {
  /**
   * Displays the label of the column
   */
  title: string;

  /**
   * Key of the user interface
   */
  key: keyof UserIncentive | 'name' | '_id';

  /**
   * Width of the column
   */
  width: string;
}

@Component({
  selector: 'ehs-user-incentive-table',
  templateUrl: './ehs-user-incentive-table.component.html',
  styleUrls: ['./ehs-user-incentive-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsUserIncentiveTableComponent {
  @Input() columns: UserIncentiveColumn[] = [
    { title: 'Name', key: 'name', width: '230px' },
    {
      title: 'Date of Birth',
      key: 'birthDay',
      width: '110px'
    },
    {
      title: 'Gender',
      key: 'gender',
      width: '80px'
    },
    {
      title: 'Client',
      key: 'companyName',
      width: '200px'
    },
    {
      title: 'Package Name',
      key: 'eventService',
      width: '200px'
    },
    {
      title: 'Registered At',
      key: 'createdAt',
      width: '170px'
    },
    {
      title: 'Admin Validation',
      key: 'adminValidation',
      width: '120px'
    },
    {
      title: 'Notes',
      key: 'notes',
      width: '150px'
    }
  ];

  @Input() rows: UserIncentive[] = [];
  @Input() hasNext?: boolean;

  @Output() loadMore = new EventEmitter();
  @Output() rowClicked = new EventEmitter<string>();

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  viewPort: CdkVirtualScrollViewport;

  /**
   * To keep the header positioning:sticky inside cdk viewport
   */
  get headerTop() {
    if (!this.viewPort || !this.viewPort['_renderedContentOffset']) {
      return '-0px';
    }

    const offset = this.viewPort['_renderedContentOffset'];

    return `-${offset}px`;
  }

  trackByFn(index: number) {
    return index;
  }

  getBirthDate(userIncentive: UserIncentive) {
    return DateUtil.formatDate(userIncentive?.birthDay);
  }
}
