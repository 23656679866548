<mat-accordion style="width: 100%"
               multi>
  <ng-container *ngFor="let notification of notifications">
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header>
        <mat-panel-title style="flex-grow:5">
          <div>{{notification.subject}}</div>
        </mat-panel-title>
        <mat-panel-description fxLayoutAlign="end"
                               fxLayoutGap="8px">
          <span>
            <mat-icon [color]="notification.unread ? 'warn' : 'primary'">
              {{notification.unread ? 'markunread' : 'drafts'}}</mat-icon>
          </span>
          <!-- <mat-checkbox [indeterminate]="indeterminate"
                          [checked]="!!allAreSelected"
                          (change)="toggleAll.emit()"
                          [disabled]="disabled"
                          color="primary">
            </mat-checkbox> -->
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div gdColumns="1fr 1fr 1fr"
           gdGap="8px">


        <ehs-card-label label="Sent"
                        [value]="(notification.createdAt) | date: 'shortDate'">
        </ehs-card-label>

        <ehs-card-label label="Read At"
                        [value]="(notification.readAt | date: 'shortDate') || 'N/A'">
        </ehs-card-label>

        <ehs-card-label label="Creator"
                        [value]="(notification.createdBy.firstName + ' ' + notification.createdBy.lastName)">
        </ehs-card-label>


      </div>
      <ehs-card-label label="Message"
                      [innerHTML]="notification.message | safeHtml">
      </ehs-card-label>

      <div fxLayout="row"
           fxLayoutAlign="end center"><button type="button"
                mat-flat-button
                (click)="deleteNotification(notification)"
                color="warn">Delete Notification
        </button></div>
    </mat-expansion-panel>
  </ng-container>
</mat-accordion>