import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { Directive } from '@angular/core';
import { DateTime } from 'luxon';

/**
 * **Note** replace with date-validator which is more generic
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[birthDate]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValidBirthDateDirective,
      multi: true
    }
  ],
  standalone: false
})
export class ValidBirthDateDirective implements Validator {
  /**
   * Returns if the date is valid with the format:
   * 'MM/dd/yyyy'
   */
  public validate(control: AbstractControl): {
    [key: string]: unknown;
  } | null {
    const { value } = control;

    if (!value) {
      // If given nothing, do nothing, as the field should be required
      return null;
    }

    if (DateTime.fromFormat(value, 'MM/dd/yyyy').isValid) {
      return null;
    }

    return {
      birthDate: {
        value
      }
    };
  }
}
