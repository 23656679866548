import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from 'ng-flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { EhsCardModule } from '../../ehs-card/ehs-card.module';
import { MatDividerModule } from '@angular/material/divider';
import { EhsEmailDebugComponent } from './ehs-email-debug.component';

@NgModule({
  declarations: [EhsEmailDebugComponent],
  imports: [
    CommonModule,
    EhsCardModule,
    FormsModule,
    // Angular Material
    FlexLayoutModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatTableModule,
    MatOptionModule,
    MatSelectModule
  ],
  exports: [EhsEmailDebugComponent]
})
export class EhsEmailDebugModule {}
