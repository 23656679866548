import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { getId } from '@common';
import { EhsUserFormDisplayService } from '../../components/ehs-user-form/ehs-user-form-display.service';
import { EhsEditUserDialogData } from './ehs-edit-user-dialog.data';

@Component({
  selector: 'ehs-ehs-edit-user-dialog',
  templateUrl: './ehs-edit-user-dialog.component.html',
  styleUrls: ['./ehs-edit-user-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsEditUserDialogComponent {
  public formLayout = this.display.getAdminUserEditLayout();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EhsEditUserDialogData,
    private dialogRef: MatDialogRef<EhsEditUserDialogComponent>,
    private snackBar: MatSnackBar,
    // Layout-display service
    public display: EhsUserFormDisplayService
  ) {}

  public onSubmit(form: NgForm) {
    if (form.invalid) {
      this.snackBar.open('Please fill out all required fields.', 'Ok');

      return;
    }

    this.dialogRef.close({
      user: {
        // This is required, as it isn't a form value
        _id: getId(this.data.user),
        ...form.value
      }
    });
  }
}
