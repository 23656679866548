import {
  Component,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
  Input
} from '@angular/core';
import {
  Company,
  getId,
  toMap,
  UserCriticalFilter,
  UserCriticalStatus
} from '@common';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

@Component({
  selector: 'ehs-user-criticals-filters',
  templateUrl: './ehs-user-criticals-filters.component.html',
  styleUrls: ['./ehs-user-criticals-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsUserCriticalsFiltersComponent {
  @Input() set filters(filters: UserCriticalFilter) {
    this._filters$.next(filters);
  }

  /**
   * Internal filters, used to handle changes to filters made over time within
   * rxjs.
   */
  public _filters$ = new BehaviorSubject<UserCriticalFilter>({});

  /**
   * If there are any filters passed, used for display purposes
   */
  public hasFilters$ = this._filters$.pipe(
    map((filters) => !!Object.values(filters || {}))
  );

  /**
   * The list of companies that are to be shown
   * in the client field autocomplete.
   * This should be handled by the parent component using
   * the company-ngrx
   */
  @Input() set companies(companies: Company[]) {
    this._companies$.next(companies);
  }

  /**
   * The current companies set
   *
   * **note** don't use this internally due to possible timing issues,
   * use the rxjs versions.
   */
  public get companies() {
    return this._companies$.value;
  }

  /**
   * The list of all companies that can be selected, set via
   * a setter primarily to offset computation costs from a getter.
   *
   * This is used internally as a "middle step".
   */
  private _companies$ = new BehaviorSubject<Company[]>([]);

  /**
   * The list of companies the user can select, we show only
   * 10 at a time.
   */
  public companiesToSelect$ = this._companies$.pipe(
    map((companies) => companies.slice(0, 10))
  );

  /**
   * Map of companies, where the key is the _id.
   * Used to lookup the currently selected company quickly.
   */
  public _companiesMap$ = this._companies$.pipe(
    map(
      (entities) =>
        toMap({
          entities,
          key: '_id'
        }) as Record<string, Company>
    )
  );

  /**
   * The company selected
   */
  public company$ = combineLatest([
    this._companiesMap$,
    this._filters$.pipe(map((filters) => filters.company))
  ]).pipe(map(([companiesMap, company]) => companiesMap[getId(company)]));

  /**
   * The value for the company field.
   */
  public companyValue$ = combineLatest([
    this._filters$.pipe(map((filters) => filters.companyName)),
    this.company$
  ]).pipe(map(([companyName, company]) => companyName || company?.name || ''));

  /**
   * If the filter changes,
   * **does not include keyup changes** such as firstName, lastName, and company
   */
  @Output() filterChange = new EventEmitter<UserCriticalFilter>();
  /**
   * If the filter changes on keyup, isolated only to firstName, lastName, and company
   */
  @Output() filterKeyup = new EventEmitter<UserCriticalFilter>();
  @Output() clear = new EventEmitter();
  @Output() search = new EventEmitter();

  public statusOptions = [
    UserCriticalStatus.OPEN,
    UserCriticalStatus.CLOSED,
    UserCriticalStatus.PENDING
  ];

  /**
   * Handles change events, should automatically search at the parent level.
   */
  public onChange(params: UserCriticalFilter) {
    this._filters$
      .pipe(take(1))
      .subscribe((filters) =>
        this.filterChange.emit({ ...filters, ...params })
      );
  }

  /**
   * Handles keyup actions, emits very often,
   * shouldn't be used to search
   */
  public onKeyup(params: UserCriticalFilter) {
    this._filters$.pipe(take(1)).subscribe((filters) =>
      this.filterKeyup.emit({
        ...filters,
        ...params
      })
    );
  }

  /**
   * Handles the display of a company, or a query-string.
   */
  public displayFn(company: Company | string) {
    return typeof company === 'string' ? company : company?.name || '';
  }

  public onClientBlur() {
    // If no company/client is selected, then we remove the query, otherwise
    // it is not clear to the end user if they selected anything or not.
    this.company$
      .pipe(
        take(1),
        filter((_) => !!_)
      )
      .subscribe(() => this.onChange({ companyName: undefined }));
  }
}
