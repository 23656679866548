import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  ViewChild,
  EventEmitter
} from '@angular/core';
import { UserPayment, UserUtil } from '@common';

interface UserPaymentsColumn {
  /**
   * Displays the label of the column
   */
  title: string;

  /**
   * Key of the user interface
   */
  key: keyof UserPayment | 'name';

  /**
   * Width of the column
   */
  width: string;
}

@Component({
  selector: 'ehs-user-payments-table',
  templateUrl: './ehs-user-payments-table.component.html',
  styleUrls: ['./ehs-user-payments-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsUserPaymentsTableComponent {
  @Input() columns: UserPaymentsColumn[] = [
    {
      title: 'Name',
      key: 'name',
      width: '230px'
    },
    {
      title: 'Age',
      key: 'birthDay',
      width: '55px'
    },
    {
      title: 'Gender',
      key: 'gender',
      width: '80px'
    },
    {
      title: 'Phone',
      key: 'phone',
      width: '100px'
    },
    {
      title: 'Email',
      key: 'email',
      width: '150px'
    },
    {
      title: 'Client',
      key: 'companyName',
      width: '200px'
    },
    {
      title: 'Registered At',
      key: 'createdAt',
      width: '170px'
    },
    {
      title: 'Status',
      key: 'status',
      width: '140px'
    },
    {
      title: 'Lab Type',
      key: 'labType',
      width: '150px'
    },
    {
      title: 'Notes',
      key: 'notes',
      width: '200px'
    }
  ];

  @Input() rows: UserPayment[] = [];
  @Input() hasNext?: boolean;

  @Output() loadMore = new EventEmitter();
  @Output() rowClicked = new EventEmitter<string>();

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  viewPort: CdkVirtualScrollViewport;

  /**
   * To keep the header positioning:sticky inside cdk viewport
   */
  get headerTop() {
    if (!this.viewPort || !this.viewPort['_renderedContentOffset']) {
      return '-0px';
    }

    const offset = this.viewPort['_renderedContentOffset'];

    return `-${offset}px`;
  }

  trackByFn(index: number) {
    return index;
  }

  getAge(payment: UserPayment) {
    return UserUtil.getAge(payment);
  }
}
