import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import {
  DateUtil,
  EventLocation,
  EventLocationUtil,
  EventStatus,
  GcfRegExportEventRequest,
  getId,
  LocationDataUtil,
  RegExportType,
  SingleEventStats,
  TimeUtil
} from '@common';
import { CompanyNgrxFacade } from '@ehs-ngrx/company-ngrx';
import { EhsConfirmDialogService } from '../../dialogs/ehs-confirmation-dialog';
import { EHS_SHOW_COMPANY_INJECTION_TOKEN } from '../../shared/ehs-show-company-injection-token';

@Component({
  selector: 'ehs-gwapps-event',
  templateUrl: './ehs-gwapps-event.component.html',
  styleUrls: ['./ehs-gwapps-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsGwappsEventComponent implements OnInit {
  /**
   * The event-location to display details for
   */
  @Input() eventLocation?: EventLocation;
  /**
   * The Stats of this event location
   */
  @Input() stats?: SingleEventStats;
  /**
   * Whether or not to hide the Event Address details from showing in the card
   */
  @Input() hideAddress?: boolean;
  /**
   * Flag if the admin can edit the rate, otherwise
   * we will show this as just "view".
   *
   * Should be available as a security scope of `CUSTOM_TIMES`
   */
  @Input() canEditRate?: boolean;

  /** Flag if we should hide extra details, used in onsite registration admin view */
  @Input() collapse?: boolean;

  // Flag if this component is being called from the client-admin library, if so we need to change the links to client from admin
  @Input() clientAdmin?: boolean;
  /**
   * Event emitted when the user clicks on the view button
   */
  @Output() view = new EventEmitter<EventLocation>();
  /**
   * Event emitted when the user selects the validate button
   */
  @Output() validate = new EventEmitter<EventLocation>();
  /**
   * Event emitted when the user clicks on the export registrations
   */
  @Output() export = new EventEmitter<EventLocation>();

  @Output() sendEventNotification = new EventEmitter<EventLocation>();

  @Output() cancelEvent = new EventEmitter<EventLocation>();

  @ViewChild(MatAccordion) accordion: MatAccordion;

  public adminLevel: '/ehs/client/company/' | '/ehs/admin/company/' =
    '/ehs/admin/company/';

  public get eventAddress(): string {
    return this.eventLocation
      ? LocationDataUtil.getDisplayLocation({
          locationData: this.eventLocation
        })
      : '';
  }

  /** Calculates the Remaining & Total for the event based on the Flu or Screening Alloted */
  public get fullEventStats(): SingleEventStats {
    // Use new CustomTime Event Utility here instead of previous functions
    const totalSlots = EventLocationUtil.getTotalSpots({
      eventLocation: this.eventLocation
    });

    const remainingScreenings =
      (totalSlots?.screeningTotal || 0) - (this.stats?.registrationsCount || 0);
    const remainingVaccinations =
      (totalSlots?.fluTotal || 0) -
      (this.stats?.vaccinationRegistrationsCount || 0);
    const resultedScreenings = this.stats?.resultedScreenings || 0;
    const resultedVaccinations = this.stats?.resultedVaccinations || 0;

    return {
      ...(this.stats || ({} as SingleEventStats)),
      remainingScreenings,
      remainingVaccinations,
      resultedScreenings,
      resultedVaccinations
    };
  }

  public get companyUrl(): string {
    return `/ehs/${this.clientAdmin ? 'client' : 'admin'}/company/${getId(
      this.eventLocation.company
    )}`;
  }

  public get serviceUrl(): string {
    return `/ehs/${this.clientAdmin ? 'client' : 'admin'}/company/${getId(
      this.eventLocation.company
    )}/service/${getId(this.eventLocation.service)}`;
  }

  public get registrationsUrl(): string {
    return `/ehs/${this.clientAdmin ? 'client' : 'admin'}/company/${getId(
      this.eventLocation.company
    )}/service/${getId(this.eventLocation.service)}/event/${getId(
      this.eventLocation
    )}`;
  }

  public get editRateUrl() {
    return `/ehs/${this.clientAdmin ? 'client' : 'admin'}/company/${getId(
      this.eventLocation.company
    )}/service/${getId(this.eventLocation.service)}/event/${getId(
      this.eventLocation
    )}/times`;
  }

  public getAmPm(time: string): string {
    return TimeUtil.timeToAmPm(time);
  }

  public get isActive(): boolean {
    const today = DateUtil.convertToString(new Date());

    return (
      this.eventLocation.registrationActive <= today &&
      this.eventLocation.registrationDeadline >= today &&
      this.eventLocation.status === EventStatus.ACTIVE
    );
  }

  constructor(
    private confirmDialog: EhsConfirmDialogService,
    private companyNgrxFacade: CompanyNgrxFacade,
    private route: ActivatedRoute,
    @Inject(EHS_SHOW_COMPANY_INJECTION_TOKEN)
    @Optional()
    public showCompany?: boolean
  ) {}

  ngOnInit(): void {
    this.adminLevel = this.clientAdmin
      ? '/ehs/client/company/'
      : '/ehs/admin/company/';
  }

  exportRegistrations() {
    this.export.emit(this.eventLocation);
  }

  exportFieldStaff() {
    this.companyNgrxFacade.exportFieldStaff({
      events: [getId(this.eventLocation)],
      exportType: RegExportType.FIELD_STAFF_REPORT
    } as GcfRegExportEventRequest);
  }

  sendNotification() {
    this.sendEventNotification.emit(this.eventLocation);
  }

  onCancelEvent() {
    if (this.eventLocation) {
      this.cancelEvent.emit(this.eventLocation);
    }
  }
}
