import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, NgModel } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[upperBound]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: UpperBoundValidatorDirective,
      multi: true
    }
  ],
  standalone: false
})
export class UpperBoundValidatorDirective {
  /**
   * An alternate form control that can be updated
   * when THIS input changes. Similar to the password+passwordConfirm control,
   * where this control is the "confirm" password.
   *
   * Fixes issues where changing the input doesn't change this validator errors.
   */
  @Input() upperBound?: NgModel;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public validate(control: AbstractControl): { [key: string]: unknown } | null {
    let { value } = control;

    if (!this.upperBound) {
      // If there is no alternative control given, then don't do anything
      return null;
    }

    value = Number(control.value);
    const upperBound = Number(this.upperBound.value);

    if (isNaN(value) || isNaN(upperBound)) {
      // If neither are numbers, then don't do anything
      return null;
    }

    if (this.upperBound?.control?.errors) {
      if (value > upperBound) {
        this.upperBound.control.setErrors([
          {
            upperBound: {
              value
            }
          }
        ]);
      }
    }

    if (value > upperBound) {
      // Return the "error"
      return {
        upperBound: {
          value
        }
      };
    }

    return null;
  }
}
