import { ObjectId } from 'mongodb';
import { Company } from '../company';
import { EventLocation } from '../event-location';
import { EventService } from '../event-service/event-service';
import { User } from '../user';

export enum SmsRecipientType {
  COMPANY = 'company',
  EVENT = 'event',
  SERVICE = 'service',
  USER = 'user'
}

export enum SmsTemplate {
  ONSITE_REMINDER = 'ONSITE_REMINDER',
  RESULTS_READY = 'RESULTS_READY',
  CUSTOM = 'CUSTOM'
}

export interface SmsNotification {
  /**
   * The type of message to send. Used to determine whether to send
   * a custom message or a predefined message (and which one).
   */
  messageType: SmsTemplate;
  /**
   * A custom message to send to the recipients
   */
  message?: string;
  /**
   * Custom Messages need to specify the recipients
   */
  recipient?: SmsRecipientType;
  /**
   * The recipient of the message
   */
  user?: string | ObjectId | User;
  /**
   * The company to send all users an sms message to
   */
  company?: string | ObjectId | Company;
  /**
   * The service to send all users an sms message to
   */
  eventService?: string | ObjectId | EventService;
  /**
   * The event to send all users an sms message to
   */
  eventLocation?: string | ObjectId | EventLocation;
}
