import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  EventLocation,
  EventService,
  InsuranceProvider,
  OnsiteUserRegistration,
  PhoneUtil,
  toMap,
  User,
  UserTest,
  UserTestId
} from '@common';
import { PreBioReqImages } from '@pre-bio-req-pdf';
import { PreFingerstickImages } from '@pre-fingerstick-pdf';
import { PreFluPdfImages } from '@pre-flu-req-pdf';
import { combineLatest, forkJoin, from, Observable } from 'rxjs';
import { mergeMap, map, take, shareReplay } from 'rxjs/operators';
import { EHS_BASE_URL_INJECTION_TOKEN } from '../../shared/ehs-base-url-injection-token';

type PreBioWithVaccineReqParams = {
  user: User;
  eventLocation: EventLocation;
  eventService: EventService;
  insuranceProviders: InsuranceProvider[];
  userTests: UserTest[] | Record<UserTestId, UserTest>;
  userRegistration: OnsiteUserRegistration;
};

@Injectable()
export class EhsBioWithVaccineReqService {
  public preBioReqImages$: Observable<PreBioReqImages>;
  public preFluPdfImages$: Observable<PreFluPdfImages>;
  public preFingerstickImages$: Observable<PreFingerstickImages>;

  constructor(
    @Inject(EHS_BASE_URL_INJECTION_TOKEN) private baseUrl: string,
    private http: HttpClient
  ) {
    this.preBioReqImages$ = this.getPreBioReqImages$();
    this.preFluPdfImages$ = this.getPreFluPdfImages$();
    this.preFingerstickImages$ = this.getPreFingerstickImages$();
  }

  private getPreBioReqImages$(): Observable<PreBioReqImages> {
    const getPath = (filename: string) => `assets/img/${filename}`;

    return forkJoin(
      // Load all fingerstick PDF related images
      [
        'ehs_logo.png',
        'bio-req-pdf/bio-staff-only.jpg',
        'bio-req-pdf/screeningInformation.jpg'
      ].map((img) => this.http.get(getPath(img), { responseType: 'blob' }))
    ).pipe(
      // Convert every image to base64
      mergeMap((blobs) =>
        forkJoin(
          blobs.map((blob) => {
            const reader = new FileReader();

            reader.readAsDataURL(blob);

            return new Promise<string>(
              (resolve) =>
                (reader.onloadend = () => resolve(reader.result as string))
            );
          })
        )
      ),
      map(
        ([logo, staffSection, screeningInformation]) =>
          ({
            logo,
            staffSection,
            screeningInformation
          }) as PreBioReqImages
      ),
      take(1),
      // Only execute this 1 time, the first time.
      shareReplay(1)
    );
  }

  private getPreFluPdfImages$(): Observable<PreFluPdfImages> {
    const getPath = (filename: string) => `assets/img/${filename}`;

    return forkJoin(
      // Load all images
      ['ehs_logo.png', 'cdc-flu-info.jpg'].map((img) =>
        this.http.get(getPath(img), { responseType: 'blob' })
      )
    ).pipe(
      // Convert every image to base64
      mergeMap((blobs) =>
        forkJoin(
          blobs.map((blob) => {
            const reader = new FileReader();

            reader.readAsDataURL(blob);

            return new Promise<string>(
              (resolve) =>
                (reader.onloadend = () => resolve(reader.result as string))
            );
          })
        )
      ),
      map(
        ([logo, cdcVaccineInfo]) =>
          ({
            logo,
            cdcVaccineInfo
          }) as PreFluPdfImages
      ),
      take(1),
      // Only execute this 1 time, the first time.
      shareReplay(1)
    );
  }

  private getPreFingerstickImages$(): Observable<PreFingerstickImages> {
    const getPath = (filename: string) => `assets/img/${filename}`;

    return forkJoin(
      // Load all fingerstick PDF related images
      ['ehs_logo.png', 'fingerstick-test-descriptions.jpeg'].map((img) =>
        this.http.get(getPath(img), { responseType: 'blob' })
      )
    ).pipe(
      // Convert every image to base64
      mergeMap((blobs) =>
        forkJoin(
          blobs.map((blob) => {
            const reader = new FileReader();

            reader.readAsDataURL(blob);

            return new Promise<string>(
              (resolve) =>
                (reader.onloadend = () => resolve(reader.result as string))
            );
          })
        )
      ),
      map(
        ([logo, fingerstickTestDescriptions]) =>
          ({
            logo,
            fingerstickTestDescriptions
          }) as PreFingerstickImages
      ),
      take(1),
      // Only execute this 1 time, the first time.
      shareReplay(1)
    );
  }

  public generate(params: PreBioWithVaccineReqParams): Observable<Buffer> {
    const {
      user,
      eventLocation,
      eventService,
      insuranceProviders,
      userTests,
      userRegistration
    } = params;

    const userTestMap = toMap({
      entities: Array.isArray(userTests) ? userTests : Object.values(userTests)
    }) as Record<UserTestId, UserTest>;

    return combineLatest([
      from(import('@pre-bio-req-pdf')),
      this.preBioReqImages$,
      this.preFluPdfImages$,
      this.preFingerstickImages$
    ]).pipe(
      take(1),
      mergeMap(
        ([
          { generatePreBioWithVaccineReq },
          preBioReqImages,
          preFluPdfImages,
          preFingerstickImages
        ]) =>
          generatePreBioWithVaccineReq({
            user,
            event: eventLocation,
            eventService,
            insuranceProviders,
            userTestMap,
            userRegistration,
            imagesPreBio: preBioReqImages,
            imagesPreFlu: preFluPdfImages,
            imagesPreFingerstick: preFingerstickImages,
            buffer: true,
            baseUrl: this.baseUrl
          }) as Promise<Buffer>
      )
    );
  }

  public generateAndDownload(params: PreBioWithVaccineReqParams): void {
    const {
      user,
      eventLocation,
      eventService,
      insuranceProviders,
      userTests,
      userRegistration
    } = params;

    const userTestMap = toMap({
      entities: Array.isArray(userTests) ? userTests : Object.values(userTests)
    }) as Record<UserTestId, UserTest>;

    combineLatest([
      from(import('@pre-bio-req-pdf')),
      this.preBioReqImages$,
      this.preFluPdfImages$,
      this.preFingerstickImages$
    ])
      .pipe(
        take(1),
        mergeMap(
          ([
            { generatePreBioWithVaccineReq },
            preBioReqImages,
            preFluPdfImages,
            preFingerstickImages
          ]) =>
            generatePreBioWithVaccineReq({
              user,
              event: eventLocation,
              eventService,
              insuranceProviders,
              userTestMap,
              userRegistration,
              imagesPreBio: preBioReqImages,
              imagesPreFlu: preFluPdfImages,
              imagesPreFingerstick: preFingerstickImages,
              baseUrl: this.baseUrl
            })
        )
      )
      .subscribe((pdfBase64) => {
        if (PhoneUtil.isSafari()) {
          PhoneUtil.openBase64Pdf(pdfBase64);

          return;
        }

        const downloadLinkElement: HTMLAnchorElement =
          document.createElement('a');

        downloadLinkElement.href = `data:application/pdf;base64,${pdfBase64}`;
        downloadLinkElement.download = `bio-with-flu-req-${new Date().getTime()}.pdf`;
        downloadLinkElement.click();
        downloadLinkElement.remove();
      });
  }

  public generateAndOpen(params: PreBioWithVaccineReqParams): void {
    const {
      user,
      eventLocation,
      eventService,
      insuranceProviders,
      userTests,
      userRegistration
    } = params;

    const userTestMap = toMap({
      entities: Array.isArray(userTests) ? userTests : Object.values(userTests)
    }) as Record<UserTestId, UserTest>;

    combineLatest([
      from(import('@pre-bio-req-pdf')),
      this.preBioReqImages$,
      this.preFluPdfImages$,
      this.preFingerstickImages$
    ])
      .pipe(
        take(1),
        mergeMap(
          ([
            { generatePreBioWithVaccineReq },
            preBioReqImages,
            preFluPdfImages,
            preFingerstickImages
          ]) =>
            generatePreBioWithVaccineReq({
              user,
              event: eventLocation,
              eventService,
              insuranceProviders,
              userTestMap,
              userRegistration,
              imagesPreBio: preBioReqImages,
              imagesPreFlu: preFluPdfImages,
              imagesPreFingerstick: preFingerstickImages,
              baseUrl: this.baseUrl
            })
        )
      )
      .subscribe((pdfBase64) => {
        PhoneUtil.openBase64Pdf(pdfBase64);
      });
  }
}
