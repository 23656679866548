import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PlatformSettings } from '@common';

@Component({
  selector: 'ehs-maintenance-dialog',
  templateUrl: './ehs-maintenance-dialog.component.html',
  styleUrls: ['./ehs-maintenance-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsMaintenanceDialogComponent {
  formGroup = this._formBuilder.group({
    enableMaintenance: this.data?.maintenance?.inMaintenance || false,
    maintenanceMessage: [this.data?.maintenance?.maintenanceMessage || '']
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: PlatformSettings | null,
    private dialogRef: MatDialogRef<EhsMaintenanceDialogComponent>,
    private _formBuilder: FormBuilder
  ) {}

  onSubmit() {
    if (!this.formGroup.valid) {
      return;
    }

    const maintenanceSetting = this.formGroup.value;

    if (!maintenanceSetting.enableMaintenance) {
      // Remove message if maintenance mode was disabled
      maintenanceSetting.maintenanceMessage = '';
    }
    this.dialogRef.close(this.formGroup.value);
  }
}
