<h1 mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content>
  <ng-container *ngIf="hasImage">
    <div style="text-align:center"><img [src]="data.imageSrc"
           height="200px"
           width="200px"></div>
  </ng-container>
  <ng-container *ngIf="hasMessages else showMessage">
    <ng-container *ngFor="let message of data.message">
      <p class="mat-body-1">
        {{ message }}
      </p>
    </ng-container>
  </ng-container>
  <ng-template #showMessage>
    <p class="mat-body-1">
      {{ data.message }}
    </p>
  </ng-template>
  <ng-container *ngIf="data.checkBox">
    <mat-checkbox [(ngModel)]="checkBoxValue"
                  color="primary">{{data.checkBox.checkBoxMessage}}</mat-checkbox>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end"
                    fxLayoutGap="16px">
  <button *ngIf="data.cancelBtn !== false"
          type="button"
          class="grey-cancel"
          mat-button
          [mat-dialog-close]="false">
    {{ data.cancelBtn || 'No' }}
  </button>
  <button type="button"
          color="primary"
          mat-button
          [mat-dialog-close]="data.checkBox ? {value: checkBoxValue} : true"
          cdkFocusInitial>
    {{ data.confirmBtn || 'Yes' }}
  </button>
</mat-dialog-actions>