import { Directive } from '@angular/core';
import { CompanyNgrxFacade } from './company-ngrx.facade';

/**
 * This directive can be used in the template to
 * "provide" the ngrx store directly into the template.
 * Cutting down on significant boilerplate within the parent
 * component.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[companyNgrx]',
  exportAs: 'companyNgrx',
  standalone: false
})
export class CompanyNgrxProviderDirective {
  constructor(public store: CompanyNgrxFacade) {}
}
