export enum EhsPlatformDebugActionType {
  SEND_RESULTS_READY_SMS = 'SEND_RESULTS_READY_SMS',
  SEND_INCENTIVE_REMINDER_EMAIL = 'SEND_INCENTIVE_REMINDER_EMAIL'
}

export interface EhsPlatformDebugAction {
  type: EhsPlatformDebugActionType;
  userId?: string;
  companyId?: string;
  eventServiceId?: string;
  eventId?: string;
}
