import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  EventLocation,
  EventService,
  OnsiteUserRegistration,
  PhoneUtil
} from '@common';
import { PreFluPdfImages } from '@pre-flu-req-pdf';
import { combineLatest, forkJoin, from, Observable } from 'rxjs';
import { map, mergeMap, shareReplay, take, tap } from 'rxjs/operators';
import { EHS_BASE_URL_INJECTION_TOKEN } from '../../shared/ehs-base-url-injection-token';

@Injectable()
export class EhsFluReqService {
  public images$: Observable<PreFluPdfImages>;

  constructor(
    @Inject(EHS_BASE_URL_INJECTION_TOKEN) private baseUrl: string,
    private http: HttpClient
  ) {
    this.images$ = this.getImages$();
  }

  private getImages$(): Observable<PreFluPdfImages> {
    const getPath = (filename: string) => `assets/img/${filename}`;

    return forkJoin(
      // Load all images
      ['ehs_logo.png', 'cdc-flu-info.jpg'].map((img) =>
        this.http.get(getPath(img), { responseType: 'blob' })
      )
    ).pipe(
      // Convert every image to base64
      mergeMap((blobs) =>
        forkJoin(
          blobs.map((blob) => {
            const reader = new FileReader();

            reader.readAsDataURL(blob);

            return new Promise<string>(
              (resolve) =>
                (reader.onloadend = () => resolve(reader.result as string))
            );
          })
        )
      ),
      map(
        ([logo, cdcVaccineInfo]) =>
          ({
            logo,
            cdcVaccineInfo
          }) as PreFluPdfImages
      ),
      take(1),
      // Only execute this 1 time, the first time.
      shareReplay(1)
    );
  }

  public generate(params: {
    userRegistrations: OnsiteUserRegistration[];
    eventLocation: EventLocation;
    eventService: EventService;
  }): Observable<Buffer> {
    const { userRegistrations, eventLocation, eventService } = params;

    return combineLatest([
      from(import('@pre-flu-req-pdf')),
      this.images$,
      from(import('pdfmake/build/pdfmake')),
      from(import('pdfmake/build/vfs_fonts'))
    ]).pipe(
      take(1),
      tap(([, , { default: pdfMake }, { default: pdfFonts }]) =>
        (<any>pdfMake).addVirtualFileSystem(pdfFonts)
      ),
      mergeMap(([{ generatePreFluReqPdf }, images, { default: pdfMake }]) =>
        generatePreFluReqPdf({
          baseUrl: this.baseUrl,
          event: eventLocation,
          eventService,
          images,
          userRegistrations
        }).then(
          (pdf) =>
            new Promise<Buffer>((resolve) =>
              pdfMake.createPdf(pdf).getBuffer(resolve)
            )
        )
      )
    );
  }

  public generateAndDownload(params: {
    userRegistrations: OnsiteUserRegistration[];
    eventLocation: EventLocation;
    eventService: EventService;
  }): void {
    const { eventLocation, eventService, userRegistrations } = params;

    combineLatest([
      from(import('@pre-flu-req-pdf')),
      this.images$,
      // Pdf libraries
      from(import('pdf-lib')),
      from(import('pdfmake/build/pdfmake')),
      from(import('pdfmake/build/vfs_fonts'))
    ])
      .pipe(
        take(1),
        mergeMap(
          ([
            { generatePreFluReqPdf },
            images,
            { PDFDocument },
            { default: pdfMake },
            { default: pdfFonts }
          ]) =>
            from(
              new Promise<Buffer>((resolve) => {
                (<any>pdfMake).addVirtualFileSystem(pdfFonts);

                generatePreFluReqPdf({
                  baseUrl: this.baseUrl,
                  event: eventLocation,
                  eventService,
                  images,
                  userRegistrations
                }).then((pdf) => pdfMake.createPdf(pdf).getBuffer(resolve));
              })
            ).pipe(
              mergeMap(async (buffer) => PDFDocument.load(buffer)),
              mergeMap((pdf) => pdf.saveAsBase64())
            )
        )
      )
      .subscribe((base64) => {
        if (PhoneUtil.isSafari()) {
          PhoneUtil.openBase64Pdf(base64);

          return;
        }

        const downloadLinkElement: HTMLAnchorElement =
          document.createElement('a');

        downloadLinkElement.href = `data:application/pdf;base64,${base64}`;
        downloadLinkElement.download = `flu-${new Date().getTime()}.pdf`;
        downloadLinkElement.click();
      });
  }

  public generateAndOpen(params: {
    userRegistrations: OnsiteUserRegistration[];
    eventLocation: EventLocation;
    eventService: EventService;
  }): void {
    const { eventLocation, eventService, userRegistrations } = params;

    combineLatest([
      from(import('@pre-flu-req-pdf')),
      this.images$,
      // Pdf libraries
      from(import('pdf-lib')),
      from(import('pdfmake/build/pdfmake')),
      from(import('pdfmake/build/vfs_fonts'))
    ])
      .pipe(
        take(1),
        mergeMap(
          ([
            { generatePreFluReqPdf },
            images,
            { PDFDocument },
            { default: pdfMake },
            { default: pdfFonts }
          ]) =>
            from(
              new Promise<Buffer>((resolve) => {
                (<any>pdfMake).addVirtualFileSystem(pdfFonts);

                generatePreFluReqPdf({
                  baseUrl: this.baseUrl,
                  event: eventLocation,
                  eventService,
                  images,
                  userRegistrations
                }).then((pdf) => pdfMake.createPdf(pdf).getBuffer(resolve));
              })
            ).pipe(
              mergeMap(async (buffer) => PDFDocument.load(buffer)),
              mergeMap((pdf) => pdf.saveAsBase64())
            )
        )
      )
      .subscribe((base64) => {
        PhoneUtil.openBase64Pdf(base64);
      });
  }
}
