import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { getId, UserDeliveryContactType, AdminNote } from '@common';
import { EhsUserCriticalsNotesTableService } from './ehs-user-criticals-notes-table.service';

@Component({
  selector: 'ehs-user-criticals-notes-table',
  templateUrl: './ehs-user-criticals-notes-table.component.html',
  styleUrls: ['./ehs-user-criticals-notes-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsUserCriticalsNotesTableComponent {
  @Input() notes: AdminNote[] = [];

  @Output() noteChange = new EventEmitter<AdminNote>();
  @Output() noteRemoved = new EventEmitter<string>();

  columns = this.tableService.getColumns();
  deliveryMethodTypes = Object.values(UserDeliveryContactType);

  constructor(private tableService: EhsUserCriticalsNotesTableService) {}

  public trackByFn(index: number) {
    return index;
  }

  onChange(change: Partial<AdminNote>, note: AdminNote) {
    this.noteChange.emit({ ...note, ...change });
  }

  onRemove(note: AdminNote) {
    this.noteRemoved.emit(getId(note));
  }
}
