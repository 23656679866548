import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MergeUserMode, UserUtil } from '@common';
import { EhsMergeUserDialogData } from './ehs-merge-user-dialog-data';
import { EhsMergeUserDialogResponse } from './ehs-merge-user-dialog-response';

@Component({
  selector: 'ehs-ehs-merge-user-dialog',
  templateUrl: './ehs-merge-user-dialog.component.html',
  styleUrls: ['./ehs-merge-user-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsMergeUserDialogComponent {
  /**
   * This is the index of the user to merge that will be set as the
   * primary user.
   */
  public primaryIndex = this.getInitialPrimaryIndex();
  /**
   * Returns if the 2 users are mixed, IE user and partial user.
   */
  public get isMixed() {
    return (
      UserUtil.getMergeMode(this.data.usersToMerge) === MergeUserMode.MIXED
    );
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EhsMergeUserDialogData,
    private dialogRef: MatDialogRef<EhsMergeUserDialogComponent>
  ) {}

  private getInitialPrimaryIndex() {
    const mode = UserUtil.getMergeMode(this.data.usersToMerge);

    if (mode === MergeUserMode.MIXED) {
      // If its mixed, select the first non-parial user.
      return this.data.usersToMerge.findIndex((user) => !user.partial);
    }

    // Just select the first one.
    return 0;
  }

  private getOrphanedUser() {
    return this.primaryIndex === 0
      ? this.data.usersToMerge[1]
      : this.data.usersToMerge[0];
  }

  public close() {
    const mergePrimaryUser = this.data.usersToMerge[this.primaryIndex];
    const mergeOrphanedUser = this.getOrphanedUser();

    return this.dialogRef.close({
      mergeOrphanedUser,
      mergePrimaryUser
    } as EhsMergeUserDialogResponse);
  }
}
