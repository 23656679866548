import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'ehs-opt-out-mfa-dialog',
  templateUrl: './ehs-opt-out-mfa-dialog.component.html',
  styleUrls: ['./ehs-opt-out-mfa-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsOptOutMFADialogComponent {}
