import { Directive } from '@angular/core';
import { EventLocationNgrxFacade } from './event-location-ngrx.facade';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[eventLocationNgrx]',
  exportAs: 'eventLocationNgrx',
  standalone: false
})
export class EventLocationNgrxProviderDirective {
  constructor(public store: EventLocationNgrxFacade) {}
}
