import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  BaseSiteUserRegistration,
  CustomPricing,
  EventService,
  EventServiceUtil,
  getId,
  ServiceType,
  toMap,
  User,
  UserTest,
  UserTestUtil
} from '@common';
import { AdminUpdateTestsDialogData } from './admin-update-tests-dialog-data';

@Component({
  selector: 'ehs-admin-update-tests-dialog',
  templateUrl: './ehs-admin-update-tests-dialog.component.html',
  styleUrls: ['./ehs-admin-update-tests-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsAdminUpdateTestsDialogComponent implements OnInit {
  public userRegistration: BaseSiteUserRegistration;
  public eventService: EventService;
  public userTests: Record<string, UserTest>;
  public user: User;
  public optionalUserTests: Array<UserTest>;
  public selectedOptionalTests: Array<UserTest>;
  public unavailableOptionalTests: Array<UserTest>;

  public get service() {
    return EventServiceUtil.getSelectedService({
      eventService: this.eventService,
      serviceType: this.userRegistration?.serviceType
    });
  }

  public get isDisabled() {
    const previousOptionalTests = new Set(
      this.userRegistration.optionalTests.map(getId)
    );
    const selectedOptionalTests = new Set(
      this.selectedOptionalTests.map(getId)
    );

    return (
      previousOptionalTests.size === selectedOptionalTests.size &&
      Array.from(selectedOptionalTests.values()).every((selectedOptionalTest) =>
        previousOptionalTests.has(selectedOptionalTest)
      )
    );
  }

  private customPricingMap: { [key: string]: CustomPricing };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AdminUpdateTestsDialogData,
    private dialogRef: MatDialogRef<EhsAdminUpdateTestsDialogComponent>
  ) {}

  ngOnInit(): void {
    this.userRegistration = this.data.userRegistration;
    this.eventService = this.data.eventService;
    this.userTests = this.data.userTests;
    this.user = this.data.user;
    this.optionalUserTests = this.getOptionalUserTests();
    this.selectedOptionalTests = this.getSelectedOptionalTests();
    this.unavailableOptionalTests = this.getUnavailableOptionalTests();

    this.customPricingMap = toMap({
      key: 'test',
      entities: this.eventService.customPricing || []
    });
  }

  /**
   * The list of all optional tests available to change
   */
  private getOptionalUserTests() {
    const service = this.service;

    if (service.type === ServiceType.PROVIDER_HEALTH) {
      // Provider health doesn't provide optional tests
      return [];
    }

    const optionalTests = EventServiceUtil.getOptionalTests({
      service,
      user: this.user,
      userTests: this.userTests,
      skipCotinineCheck: true
    });

    // Make a shallow copy just in-case to prevent mutation.
    return [...optionalTests].sort((a, b) =>
      UserTestUtil.getName(a).toLowerCase() <
      UserTestUtil.getName(b).toLowerCase()
        ? -1
        : 1
    );
  }

  public getUnavailableOptionalTests(): Array<UserTest> {
    const optionalTestInReg = (this.userRegistration.optionalTests || [])
      .map((optionalTest) => this.userTests[getId(optionalTest)])
      .filter((_) => !!_);

    return optionalTestInReg.filter(
      (optionalTest) =>
        !this.optionalUserTests
          .map(getId)
          .find(
            (selectedId) =>
              selectedId === getId(optionalTest) ||
              selectedId === getId(optionalTest.alternativeTest)
          )
    );
  }

  /**
   * The currently selected optional tests,
   * can be updated and will be returned as-is.
   */
  private getSelectedOptionalTests(): Array<UserTest> {
    return (this.userRegistration.optionalTests || [])
      .map((optionalTest) => this.userTests[getId(optionalTest)])
      .filter((_) => !!_);
  }

  getPrice(optionalTest: UserTest) {
    const customPrice = this.customPricingMap[getId(optionalTest)]?.cost;

    return typeof customPrice === 'number'
      ? customPrice
      : optionalTest.cost || 0;
  }

  getName(optionalTest: UserTest): string {
    return optionalTest.name || optionalTest.technicalName;
  }

  toggleOptionalTest(optionalTest: UserTest) {
    const selectedOptionalTestIds = this.selectedOptionalTests.map(getId);

    if (
      selectedOptionalTestIds.find(
        (selectedTest) =>
          getId(selectedTest) === getId(optionalTest) ||
          getId(selectedTest) === getId(optionalTest.alternativeTest)
      )
    ) {
      this.selectedOptionalTests = this.selectedOptionalTests.filter(
        (selectedOptionalTest) =>
          getId(selectedOptionalTest) !== getId(optionalTest) &&
          getId(selectedOptionalTest) !== getId(optionalTest.alternativeTest)
      );

      return;
    }

    this.selectedOptionalTests.push(optionalTest);
  }

  isChecked(optionalTest: UserTest): boolean {
    return !!(this.selectedOptionalTests || [])
      .map(getId)
      .find(
        (selectedTest) =>
          selectedTest === getId(optionalTest) ||
          selectedTest === getId(optionalTest.alternativeTest)
      );
  }

  /**
   * Returns the newly selected optional tests
   */
  close() {
    this.dialogRef.close(this.selectedOptionalTests);
  }
}
