import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  ViewChild,
  EventEmitter
} from '@angular/core';
import { UserHomeTest, UserHomeTestStatus, UserUtil } from '@common';

interface UserHomeTestsColumn {
  /**
   * Displays the label of the column
   */
  title: string;

  /**
   * Key of the user interface
   */
  key: keyof UserHomeTest | 'name' | '_id';

  /**
   * Width of the column
   */
  width: string;
}

@Component({
  selector: 'ehs-user-home-tests-table',
  templateUrl: './ehs-user-home-tests-table.component.html',
  styleUrls: ['./ehs-user-home-tests-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsUserHomeTestsTableComponent {
  @Input() columns: UserHomeTestsColumn[] = [
    {
      title: 'Name',
      key: 'name',
      width: '230px'
    },
    {
      title: 'Age',
      key: 'birthDay',
      width: '55px'
    },
    {
      title: 'Gender',
      key: 'gender',
      width: '80px'
    },
    {
      title: 'Phone',
      key: 'phone',
      width: '100px'
    },
    {
      title: 'Email',
      key: 'email',
      width: '150px'
    },
    {
      title: 'Client',
      key: 'companyName',
      width: '200px'
    },
    {
      title: 'Registered At',
      key: 'createdAt',
      width: '170px'
    },
    {
      title: 'Status',
      key: 'status',
      width: '140px'
    },
    {
      title: 'Lab Type',
      key: 'labType',
      width: '150px'
    },
    {
      title: 'Notes',
      key: 'notes',
      width: '200px'
    }
  ];

  @Input() rows: UserHomeTest[] = [];
  @Input() hasNext?: boolean;

  @Output() loadMore = new EventEmitter();
  @Output() rowClicked = new EventEmitter<string>();

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  viewPort: CdkVirtualScrollViewport;

  /**
   * To keep the header positioning:sticky inside cdk viewport
   */
  get headerTop() {
    if (!this.viewPort || !this.viewPort['_renderedContentOffset']) {
      return '-0px';
    }

    const offset = this.viewPort['_renderedContentOffset'];

    return `-${offset}px`;
  }

  trackByFn(index: number) {
    return index;
  }

  getAge(userHomeTest: UserHomeTest) {
    return UserUtil.getAge(userHomeTest);
  }

  getChipColor(status: UserHomeTestStatus) {
    switch (status) {
      case UserHomeTestStatus.CLOSED:
      case UserHomeTestStatus.PENDING:
        return 'primary';

      case UserHomeTestStatus.OPEN:
        return 'warn';

      case UserHomeTestStatus.CANCELED:
        return 'accent';

      default:
        return 'primary';
    }
  }
}
