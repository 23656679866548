import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getId, IconType, TeamIcons, TeamIconsSrcs } from '@common';
import { EhsEditTeamDialogData } from './ehs-edit-team-dialog.data';
import { BehaviorSubject, take } from 'rxjs';
import { IconSelectDialogService } from '../icon-select-dialog';

@Component({
  selector: 'ehs-edit-team-dialog',
  templateUrl: './ehs-edit-team-dialog.component.html',
  styleUrls: ['./ehs-edit-team-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsEditTeamDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EhsEditTeamDialogData,
    private dialogRef: MatDialogRef<EhsEditTeamDialogComponent>,
    private iconSelectDialogService: IconSelectDialogService
  ) {}

  public selectedIcon$ = new BehaviorSubject<TeamIcons>(
    this.data.healthProgramTeam?.teamIcon || TeamIcons.TEAM
  );

  public healthProgram = this.data.healthProgram;
  public teamName = this.data.healthProgramTeam?.name || '';

  public getTeamIconSrc(teamIcon: TeamIcons) {
    if (!teamIcon) {
      return TeamIconsSrcs[TeamIcons.TEAM];
    }

    return TeamIconsSrcs[teamIcon];
  }

  public openTeamIconSelectDialog() {
    this.iconSelectDialogService
      .open({
        type: IconType.TEAM
      })
      .pipe(take(1))
      .subscribe((icon) => {
        if (icon?.icon) {
          this.selectedIcon$.next(icon.icon as TeamIcons);
        }
      });
  }

  public submit(form: NgForm) {
    if (!form.valid) {
      return;
    }

    this.dialogRef.close({
      _id: getId(this.data.healthProgramTeam),
      healthProgram: this.data.healthProgram,
      name: this.teamName,
      teamIcon: this.selectedIcon$.getValue()
    });
  }
}
