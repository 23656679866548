import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PartialUser, User } from '@common';
import {
  EhsUserInfoColumn,
  EhsUserInfoColumnValueOverride,
  EhsUserInfoColumnWithProp,
  isEhsUserInfoColumnWithProp,
  isEhsUserInfoColumValueOverride
} from './ehs-user-info-column';

@Component({
  selector: 'ehs-user-info',
  templateUrl: './ehs-user-info.component.html',
  styleUrls: ['./ehs-user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsUserInfoComponent {
  /**
   * The user to display.
   *
   * Internally we can handle partials, and the behavior when a property is missing
   * by default is to display blank. To change this behavior use `hideFalsy` which
   * will hide "falsy" values in specific cases where that make sense
   */
  @Input() user: Partial<User> | Partial<PartialUser> | null = null;

  /**
   * The list of columns to display for the user
   */
  @Input() columns: EhsUserInfoColumn[] = [];

  /**
   * If we are to hide "falsy" values by default. This behavior may change
   * depending on the property. This defaults to `false` so we can show
   * a "label" but no value if not given.
   */
  @Input() hideFalsy = false;

  public isOverride(
    column: EhsUserInfoColumn
  ): column is EhsUserInfoColumnValueOverride {
    return isEhsUserInfoColumValueOverride(column);
  }

  public isProp(
    column: EhsUserInfoColumn
  ): column is EhsUserInfoColumnWithProp {
    return isEhsUserInfoColumnWithProp(column);
  }

  /**
   * Template wrapper for typeof check
   */
  public isFunction(override: unknown) {
    return typeof override === 'function';
  }
}
