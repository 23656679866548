import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { isValidEmail } from '@common';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appEmail]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: EmailValidatorDirective,
      multi: true
    }
  ],
  standalone: false
})
export class EmailValidatorDirective implements Validator {
  public validate(
    control: AbstractControl
  ): { [key: string]: unknown | null } | null {
    return isValidEmail(control.value, {
      // Allow empty, as the `required` field should prevent blank submissions
      allowEmpty: true
    })
      ? null
      : {
          email: {
            value: control.value
          }
        };
  }
}
