import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
  Output,
  EventEmitter
} from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import {
  BaseGroupType,
  getId,
  getUserInsuranceHolderName,
  getUserInsuranceTypeName,
  InsuranceFields,
  InsuranceProvider,
  UserInsurance,
  UserInsuranceHolder,
  UserInsuranceType
} from '@common';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { mergeMap, skip, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ehs-insurance-form',
  templateUrl: './insurance-form.component.html',
  styleUrls: ['./insurance-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  standalone: false
})
export class InsuranceFormComponent implements OnInit {
  @Input() groupType: BaseGroupType;
  /**
   * The data to set as the form data.
   */
  @Input() userInsuranceFormData: Partial<UserInsurance>;
  /**
   * The list of insurance providers
   */
  @Input() insuranceProviders: InsuranceProvider[] = [];
  /**
   * If we are to show a spinner in the insurance providers
   */
  @Input() insuranceProvidersLoading: boolean;
  @Input() insuranceFields: InsuranceFields[];
  /**
   * The nested form property to update within the form.
   * Defaults to `insurance`
   */
  @Input() modelGroup = 'insurance';
  /**
   * If all the fields are required
   * **defaults to true**
   */
  @Input() required = true;
  @ViewChildren(NgModel) set ngModels(ngModels: QueryList<NgModel>) {
    this._ngModels.next(ngModels.toArray());
  }

  @Output() insuranceChange = new EventEmitter<Partial<UserInsurance>>();

  public showGroupNumber = false;
  public showPolicyNumber = false;
  public showInsuranceTypes = false;

  private _ngModels = new ReplaySubject<NgModel[]>(1);
  /**
   * If any of the data changed in the form
   */
  public changed: boolean;
  private takeUntil = new Subject<void>();
  get showPrimaryAccountHolderName(): boolean {
    if (!this.userInsuranceFormData?.primaryAccountHolder) {
      return false;
    }

    return (
      this.userInsuranceFormData?.primaryAccountHolder !==
      UserInsuranceHolder.YOURSELF
    );
  }

  get showPrimaryAccountHolderDob(): boolean {
    if (!this.userInsuranceFormData?.primaryAccountHolder) {
      return false;
    }

    return (
      this.userInsuranceFormData?.primaryAccountHolder !==
      UserInsuranceHolder.YOURSELF
    );
  }

  // Only show `Yourself` Option for now
  public readonly insuranceHolders = Object.values(UserInsuranceHolder).filter(
    (val) => val === UserInsuranceHolder.YOURSELF
  );

  // Currently, display all types except the Not applicable type
  public readonly insuranceTypes = Object.values(UserInsuranceType).filter(
    (val) => val !== UserInsuranceType.NA
  );

  constructor(
    private container: ControlContainer,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (!this.userInsuranceFormData.primaryAccountHolder) {
      this.userInsuranceFormData.primaryAccountHolder =
        UserInsuranceHolder.YOURSELF;
    }

    /**
     * Set the field to undefined to prevent users from
     * getting through registration with a pre-existing
     * insurance provider. Forces them to select from the
     * allowed providers.
     */
    if (
      !(this.insuranceProviders || []).find(
        (provider) =>
          getId(provider) === this.userInsuranceFormData?.insuranceProvider
      )
    ) {
      this.userInsuranceFormData.insuranceProvider = undefined;
    }

    // Only display insurance fields if "turned on" by whoever sets up service record.
    (this.insuranceFields || []).forEach((field) => {
      if (field === InsuranceFields.GROUP_NUMBER) {
        this.showGroupNumber = true;
      }

      if (field === InsuranceFields.POLICY_NUMBER) {
        this.showPolicyNumber = true;
      }

      if (field === InsuranceFields.INSURANCE_TYPE) {
        this.showInsuranceTypes = true;
      }
    });

    // Otherwise pass default values.
    if (!this.showInsuranceTypes) {
      this.userInsuranceFormData.insuranceType = UserInsuranceType.NA;
    }

    if (!this.showGroupNumber) {
      this.userInsuranceFormData.groupNumber = 'not applicable';
    }

    if (!this.showPolicyNumber) {
      this.userInsuranceFormData.policyNumber = 'not applicable';
    }

    this.container.valueChanges
      .pipe(takeUntil(this.takeUntil))
      .subscribe(() => this.cdr.markForCheck());

    this._ngModels
      .pipe(
        mergeMap((ngModels) =>
          combineLatest(ngModels.map((ngModel) => ngModel.valueChanges))
        ),
        skip(1),
        takeUntil(this.takeUntil)
      )
      .subscribe(() => (this.changed = true));
  }

  public compareInsuranceProvidersFn(
    e1: InsuranceProvider | string,
    e2: InsuranceProvider | string
  ): boolean {
    return getId(e1) === getId(e2);
  }

  public onChange() {
    this.insuranceChange.emit(this.userInsuranceFormData);
  }

  public onHolderChange(event: MatSelectChange) {
    const value = event.value as UserInsuranceHolder;

    if (!value || value === UserInsuranceHolder.YOURSELF) {
      // Remove these 2 properties if yourself is selected
      delete this.userInsuranceFormData.primaryPolicyHolderDob;
      delete this.userInsuranceFormData.primaryPolicyHolderName;
    }
  }

  public getInsuranceName(type: UserInsuranceType): string {
    return getUserInsuranceTypeName(type);
  }

  public getInsuranceHolderName(holder: UserInsuranceHolder): string {
    return getUserInsuranceHolderName(holder);
  }
}
