import {
  AnimationMetadata,
  style,
  animate,
  AnimationBuilder,
  AnimationPlayer
} from '@angular/animations';
import {
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit
} from '@angular/core';
import { EHS_UI_FEATURE_FLAGS_INJECTION_TOKEN } from '../providers/ehs-ui-feature-flags-injection-token';

/**
 * Get the animation for the button hover effect
 * @param state hover or unhover
 * @param color color of the underline
 * @returns
 */
const getButtonHoverAnimation = (params: {
  state: 'hover' | 'unhover';
  color?: string;
}) => {
  const { state, color } = params;

  const colorValue = color || 'white';

  return state === 'hover'
    ? [
        style({ width: '0%', borderBottom: `2px solid transparent` }),
        animate(
          '0.2s ease',
          style({ width: '100%', borderBottom: `2px solid ${colorValue}` })
        )
      ]
    : [
        style({ width: '100%', borderBottom: `2px solid ${colorValue}` }),
        animate(
          '0.2s ease',
          style({ width: '0%', borderBottom: `2px solid transparent` })
        )
      ];
};

@Directive({
  selector: '[ehsButtonAnimation]',
  standalone: false
})
export class EhsButtonAnimationDirective implements OnInit {
  private player: AnimationPlayer;
  private ready = false;
  private showButtonAnimation = false;

  /**
   * Underlines the button text element when a user hovers over the button.
   **/
  @Input()
  ehsButtonAnimation: any;

  /**
   * Sets the color of the underline element which appears when the user hovers over the button.
   */
  @Input()
  animationColor?: string;

  // Attach mouseenter event listeners to the button element.
  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.ready && this.showButtonAnimation) {
      const animation = getButtonHoverAnimation({
        state: 'hover',
        color: this.animationColor
      });

      this.triggerAnimation(animation);
    }
  }

  // Attach mouseleave event listeners to the button element.
  @HostListener('mouseleave')
  onMouseLeave() {
    if (this.ready && this.showButtonAnimation) {
      const animation = getButtonHoverAnimation({
        state: 'unhover',
        color: this.animationColor
      });

      this.triggerAnimation(animation);
    }
  }

  constructor(
    @Inject(EHS_UI_FEATURE_FLAGS_INJECTION_TOKEN)
    public featureFlags: Record<string, boolean>,
    private builder: AnimationBuilder,
    private element: ElementRef
  ) {
    this.showButtonAnimation = this.featureFlags?.showAnimations || false;
  }

  ngOnInit() {
    if (this.showButtonAnimation) {
      this.appendUnderlineChild();
    }
  }

  /**
   * Appends the underline div element to the button element based on the button type.
   * If the button type is not supported, the ready flag is set to false to prevent triggering
   * the animation and throwing errors.
   */
  private appendUnderlineChild() {
    const el = this.element.nativeElement as HTMLElement;
    const classList = el.classList;

    const buttonType = el.tagName.toLowerCase();

    let textElementClass;

    // Check if the element is a button or an anchor tag
    if (buttonType === 'button' || buttonType === 'a') {
      // Set the text element class based on the button type

      if (classList.contains('mdc-button')) {
        textElementClass = 'span.mdc-button__label';
      }

      if (classList.contains('mat-mdc-menu-item')) {
        textElementClass = 'span.mat-mdc-menu-item-text';
      }

      if (classList.contains('learn-more')) {
        textElementClass = 'span.learn-more-text';
      }

      if (classList.contains('link-btn')) {
        textElementClass = 'span.link-btn-text';
      }
    }

    if (textElementClass) {
      const document = el.ownerDocument;

      // Create the underline div element
      const div = document.createElement('div');

      div.id = 'underline';

      // Find the text element and append the underline div to it
      const elements = el.querySelectorAll(textElementClass);

      if (elements?.length > 0) {
        const label = elements[0];

        label.appendChild(div);
      }

      // Set the ready flag to true
      this.ready = true;
    } else {
      // Otherwise, set the ready flag to false
      this.ready = false;
    }
  }

  private triggerAnimation(metadeta: AnimationMetadata | AnimationMetadata[]) {
    if (this.player) {
      this.player.destroy();
    }

    const factory = this.builder.build(metadeta);

    const underline = this.element.nativeElement.querySelector('#underline');

    const player = factory.create(underline);

    player.play();
  }
}
