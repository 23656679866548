import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayBloodPressure',
  standalone: false
})
export class DisplayBloodPressure implements PipeTransform {
  transform(bloodPressure?: { systolic?: number; diastolic?: number }): string {
    if (!bloodPressure) {
      return 'Not Specified';
    }

    return bloodPressure.systolic && bloodPressure.diastolic
      ? `${bloodPressure.systolic} / ${bloodPressure.diastolic}`
      : 'Not Specified';
  }
}
