import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { CustomReportImages, customReportPageNames } from '@common';

@Component({
  selector: 'ehs-admin-custom-page-upload-input',
  templateUrl: './ehs-admin-custom-page-upload-input.component.html',
  styleUrls: ['./ehs-admin-custom-page-upload-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsAdminCustomPageUploadInputComponent {
  @Input() key?: keyof Omit<
    CustomReportImages,
    | 'eventService'
    | 'company'
    | 'language'
    | 'name'
    | 'reportType'
    | '_id'
    | 'createdAt'
    | 'updatedAt'
    | 'status'
  >;

  @Input() currentPage?: string;

  @Output() fileChange = new EventEmitter<{
    files: FileList;
    key: keyof Omit<
      CustomReportImages,
      | 'eventService'
      | 'company'
      | 'language'
      | 'name'
      | 'reportType'
      | '_id'
      | 'createdAt'
      | 'updatedAt'
      | 'status'
    >;
  }>();

  public customReportPageNames = customReportPageNames;

  public onFileUpload(files: FileList) {
    if (this.key) {
      this.fileChange.emit({ files, key: this.key });
    }
  }
}
