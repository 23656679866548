import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { PhoneUtil } from '@common';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appPhoneFormat]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: PhoneFormatDirective, multi: true }
  ],
  standalone: false
})
export class PhoneFormatDirective implements Validator {
  /**
   * Returns an error if the Google Phone library can't validate the phone number
   */
  public validate(control: AbstractControl): { [key: string]: unknown } | null {
    try {
      return PhoneUtil.isValidPhone(control.value, {
        allowEmpty: true
      })
        ? null
        : { wrongPhone: { valid: false } };
    } catch (e) {
      return { wrongPhone: { valid: false } };
    }
  }
}
