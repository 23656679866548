import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { EventService, EventServiceStats, getId } from '@common';

@Component({
  selector: 'ehs-gwapps-active-service-stats',
  templateUrl: './ehs-gwapps-active-service-stats.component.html',
  styleUrls: ['./ehs-gwapps-active-service-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsGwappsActiveServiceStatsComponent {
  /**
   * The relevant event-service we are displaying data for
   */
  @Input() eventService?: EventService | null;

  @Input() eventServiceStats?: EventServiceStats | null;

  @Input() clientAdmin?: boolean;

  public onsiteService = this.eventServiceStats;

  public get registrationTotals(): number {
    return (
      (this.eventServiceStats?.onsite?.userRegistrations || 0) +
      (this.eventServiceStats?.offsite?.userRegistrations || 0) +
      (this.eventServiceStats?.providerHealth?.userRegistrations || 0)
    );
  }

  public get resultsTotals(): number {
    return (
      (this.eventServiceStats?.onsite?.userResults || 0) +
      (this.eventServiceStats?.offsite?.userResults || 0) +
      (this.eventServiceStats?.providerHealth?.userResults || 0)
    );
  }

  public get companyUrl(): string {
    return `/ehs/${this.clientAdmin ? 'client' : 'admin'}/company/${getId(
      this.eventService?.company
    )}`;
  }

  public get eventServiceUrl(): string {
    return `/ehs/${this.clientAdmin ? 'client' : 'admin'}/company/${getId(
      this.eventService?.company
    )}/service/${getId(this.eventService)}`;
  }
}
