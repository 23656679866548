import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  EventLocation,
  EventService,
  OnsiteUserRegistration,
  PhoneUtil,
  User
} from '@common';
import { PreFingerstickImages } from '@pre-fingerstick-pdf';
import { combineLatest, forkJoin, from, Observable } from 'rxjs';
import { map, mergeMap, shareReplay, take, tap } from 'rxjs/operators';
import { EHS_BASE_URL_INJECTION_TOKEN } from '../../shared/ehs-base-url-injection-token';

@Injectable()
export class EhsFingerstickReqService {
  public images$: Observable<PreFingerstickImages>;

  constructor(
    @Inject(EHS_BASE_URL_INJECTION_TOKEN) private baseUrl: string,
    private http: HttpClient
  ) {
    this.images$ = this.getImages$();
  }

  private getImages$(): Observable<PreFingerstickImages> {
    const getPath = (filename: string) => `assets/img/${filename}`;

    return forkJoin(
      // Load all fingerstick PDF related images
      ['ehs_logo.png', 'fingerstick-test-descriptions.jpeg'].map((img) =>
        this.http.get(getPath(img), { responseType: 'blob' })
      )
    ).pipe(
      // Convert every image to base64
      mergeMap((blobs) =>
        forkJoin(
          blobs.map((blob) => {
            const reader = new FileReader();

            reader.readAsDataURL(blob);

            return new Promise<string>(
              (resolve) =>
                (reader.onloadend = () => resolve(reader.result as string))
            );
          })
        )
      ),
      map(
        ([logo, fingerstickTestDescriptions]) =>
          ({
            logo,
            fingerstickTestDescriptions
          }) as PreFingerstickImages
      ),
      take(1),
      // Only execute this 1 time, the first time.
      shareReplay(1)
    );
  }

  /**
   * Same as generate and download, except we return a buffer
   * of the final pdf.
   */
  public generate(params: {
    user: User;
    userRegistration: OnsiteUserRegistration;
    eventLocation: EventLocation;
    eventService: EventService;
  }): Observable<Buffer> {
    const { user, userRegistration, eventLocation, eventService } = params;

    return combineLatest([
      from(import('@pre-fingerstick-pdf')),
      this.images$,
      // Pdf libraries
      from(import('pdfmake/build/pdfmake')),
      from(import('pdfmake/build/vfs_fonts'))
    ]).pipe(
      // Take(1),
      tap(([, , { default: pdfMake }, { default: pdfFonts }]) =>
        (<any>pdfMake).addVirtualFileSystem(pdfFonts)
      ),
      mergeMap(
        ([{ generatePreFingerstickPdf }, images, { default: pdfMake }]) =>
          generatePreFingerstickPdf({
            baseUrl: this.baseUrl,
            eventLocation,
            images,
            user,
            userRegistration,
            eventService
          }).then((pdf) => pdfMake.createPdf(pdf))
      ),
      mergeMap(
        (pdf) => new Promise<Buffer>((resolve) => pdf.getBuffer(resolve))
      )
    );
  }

  public generateAndDownload(params: {
    user: User;
    userRegistration: OnsiteUserRegistration;
    eventLocation: EventLocation;
    eventService: EventService;
  }): void {
    const { eventLocation, user, userRegistration, eventService } = params;

    combineLatest([
      from(import('@pre-fingerstick-pdf')),
      this.images$,
      // Pdf libraries
      from(import('pdf-lib')),
      from(import('pdfmake/build/pdfmake')),
      from(import('pdfmake/build/vfs_fonts'))
    ])
      .pipe(
        take(1),
        mergeMap(
          ([
            { generatePreFingerstickPdf },
            images,
            { PDFDocument },
            { default: pdfMake },
            { default: pdfFonts }
          ]) =>
            from(
              new Promise<Buffer>((resolve) => {
                (<any>pdfMake).addVirtualFileSystem(pdfFonts);

                generatePreFingerstickPdf({
                  baseUrl: this.baseUrl,
                  eventLocation,
                  images,
                  user,
                  userRegistration,
                  eventService
                }).then((pdf) => pdfMake.createPdf(pdf).getBuffer(resolve));
              })
              // TODO: might need to load other image?
            ).pipe(
              mergeMap(async (buffer) => PDFDocument.load(buffer)),
              mergeMap((pdf) => pdf.saveAsBase64())
            )
        )
      )
      .subscribe((base64) => {
        if (PhoneUtil.isSafari()) {
          PhoneUtil.openBase64Pdf(base64);

          return;
        }

        const downloadLinkElement: HTMLAnchorElement =
          document.createElement('a');

        downloadLinkElement.href = `data:application/pdf;base64,${base64}`;
        downloadLinkElement.download = `-${userRegistration.createdAt}.pdf`;
        downloadLinkElement.click();
      });
  }

  public generateAndOpen(params: {
    user: User;
    userRegistration: OnsiteUserRegistration;
    eventLocation: EventLocation;
    eventService: EventService;
  }): void {
    const { eventLocation, user, userRegistration, eventService } = params;

    combineLatest([
      from(import('@pre-fingerstick-pdf')),
      this.images$,
      // Pdf libraries
      from(import('pdf-lib')),
      from(import('pdfmake/build/pdfmake')),
      from(import('pdfmake/build/vfs_fonts'))
    ])
      .pipe(
        take(1),
        mergeMap(
          ([
            { generatePreFingerstickPdf },
            images,
            { PDFDocument },
            { default: pdfMake },
            { default: pdfFonts }
          ]) =>
            from(
              new Promise<Buffer>((resolve) => {
                (<any>pdfMake).addVirtualFileSystem(pdfFonts);

                generatePreFingerstickPdf({
                  baseUrl: this.baseUrl,
                  eventLocation,
                  images,
                  user,
                  userRegistration,
                  eventService
                }).then((pdf) => pdfMake.createPdf(pdf).getBuffer(resolve));
              })
              // TODO: might need to load other image?
            ).pipe(
              mergeMap(async (buffer) => PDFDocument.load(buffer)),
              mergeMap((pdf) => pdf.saveAsBase64())
            )
        )
      )
      .subscribe((base64) => {
        PhoneUtil.openBase64Pdf(base64);
      });
  }
}
