import { Pipe, PipeTransform } from '@angular/core';
import { inchesToFeetAndInches } from '@common';

@Pipe({
  name: 'displayHeight',
  standalone: false
})
export class DisplayHeightPipe implements PipeTransform {
  transform(
    height?:
      | number
      | {
          inches?: number;
          feet?: number;
        }
  ): string {
    if (!height) {
      return 'Not Specified';
    }

    if (typeof height === 'number') {
      const { feet, inches } = inchesToFeetAndInches(height);

      return `${feet}' ${inches}"`;
    }

    return height.feet && height.inches
      ? `${height.feet}' ${height.inches}"`
      : 'Not Specified';
  }
}
