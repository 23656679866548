import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ehs-card-label',
  templateUrl: './ehs-card-label.component.html',
  styleUrls: ['./ehs-card-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsCardLabelComponent {
  /**
   * The Label Name
   */
  @Input() label?: string;
  /**
   * The value of the Property
   */
  @Input() value?: string | number;
  /**
   * The Internal Link. Uses `routerLink`
   */
  @Input() internalLink?: string | string[];
  /**
   * Link to redirect but that is external to the app.
   * Uses `href`
   */
  @Input() externalLink?: string;
  /**
   * Whether the Link should open in a new tab or not
   */
  @Input() newTab?: boolean;
  /**
   * Styles to apply on the value
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() valueStyles?: any;
  /**
   * Styles to apply on the label
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() labelStyles?: any;
}
