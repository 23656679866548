import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { QueryParamsHandling, Router } from '@angular/router';
import {
  EhsNavActionData,
  EhsNavData,
  EhsNavDataType,
  getEhsNavDataType,
  isEhsNavActionData,
  isEhsNavElementData,
  isEhsNavNestedData
} from '../../../models/ehs-nav-data';

@Component({
  selector: 'ehs-header-element',
  templateUrl: './ehs-header-element.component.html',
  styleUrls: ['./ehs-header-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsHeaderElementComponent {
  /**
   * The individual nav data for this element.
   */
  @Input() data?: EhsNavData;

  /**
   * Action emitted from ehs-nav-action elements
   */
  @Output() action = new EventEmitter<EhsNavActionData>();

  constructor(private router: Router) {}

  public get type(): EhsNavDataType {
    return getEhsNavDataType(this.data);
  }

  /**
   * When the nav element is clicked, we navigate to the route
   * and reload page to ensure the correct state.
   */
  public onNavClick() {
    const urlTree = this.router.createUrlTree([...this.route], {
      queryParams: this.queryParams,
      queryParamsHandling: this.queryParamsHandling
    });

    window.open(urlTree.toString(), '_self');
  }

  /**
   * The route from the data, will be an empty array
   * if not a route element.
   */
  public get route(): string | string[] {
    return this.data && isEhsNavElementData(this.data) ? this.data.route : [];
  }

  public get queryParams(): Record<string, string> | undefined {
    return this.data && isEhsNavElementData(this.data)
      ? this.data.queryParams
      : undefined;
  }

  public get queryParamsHandling(): QueryParamsHandling | undefined {
    return this.data && isEhsNavElementData(this.data)
      ? this.data.queryParamsHandling
      : undefined;
  }

  /**
   * The nested data elements to display, if there are any
   */
  public get nestedData(): EhsNavData[] {
    return this.data && isEhsNavNestedData(this.data) ? this.data.data : [];
  }

  public emitAction(data: EhsNavData, event?: MouseEvent) {
    event?.stopPropagation();

    if (isEhsNavActionData(data)) {
      this.action.emit(data);
    }
  }
}
