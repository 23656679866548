import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Company, getId, isDbDocument, toMap } from '@common';

/**
 * This validator requires an external input to verify that the
 * control's value is an actual company loaded into the state.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[isCompany]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: CompanyValidatorDirective,
      multi: true
    }
  ],
  standalone: false
})
export class CompanyValidatorDirective {
  /**
   * Main input for this directive that will be used to match
   */
  @Input() isCompany?: Record<string, Company> | Company[];

  public get companiesMap(): Record<string, Company> {
    return Array.isArray(this.isCompany)
      ? (toMap({
          entities: this.isCompany
        }) as Record<string, Company>)
      : this.isCompany || {};
  }

  public validate(control: AbstractControl): {
    isCompany: {
      value: unknown;
    };
  } | null {
    const value = control.value;

    if (!value) {
      // An empty value is not an error, use the `required`
      // validation to check against this.
      return null;
    }

    if (typeof value === 'object' && isDbDocument(value)) {
      return this.companiesMap[getId(value)]
        ? null
        : {
            isCompany: {
              value
            }
          };
    }

    if (typeof value === 'string') {
      return this.companiesMap[value]
        ? null
        : {
            isCompany: {
              value
            }
          };
    }

    return {
      isCompany: {
        value
      }
    };
  }
}
