import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import {
  getId,
  OffsiteService,
  PaymentTestCost,
  UserTest,
  UserTestId,
  UserTestUtil
} from '@common';
import { ObjectId } from 'mongodb';

interface UserPaymentsTestsCostTableColumn {
  title: string;
  key: 'testName' | 'cost';
  width: string;
}

@Component({
  selector: 'ehs-user-payments-tests-cost-table',
  templateUrl: './ehs-user-payments-tests-cost-table.component.html',
  styleUrls: ['./ehs-user-payments-tests-cost-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsUserPaymentsTestsCostTableComponent {
  @Input() testCosts: PaymentTestCost[] = [];
  @Input() total: number;
  @Input() userTests: Record<UserTestId, UserTest>;
  @Input() service?: OffsiteService | null = null;
  @Input() loading?: boolean | null = null;

  columns: UserPaymentsTestsCostTableColumn[] = [
    {
      title: 'Test Name',
      key: 'testName',
      width: '300px'
    },
    {
      title: 'Cost',
      key: 'cost',
      width: '100%'
    }
  ];

  isPanel(userTest: string | ObjectId | UserTest) {
    return getId(userTest) === getId(this.service?.panel);
  }

  getUserTestName(userTest: string | ObjectId | UserTest) {
    return UserTestUtil.getName(this.userTests[getId(userTest)]);
  }
}
