import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { EhsNavActionData, EhsNavData } from '../../models/ehs-nav-data';
import {
  isEndEhsNavData,
  isStartEhsNavData
} from '../../utils/ehs-nav-data-utils';

@Component({
  selector: 'ehs-header',
  templateUrl: './ehs-header.component.html',
  styleUrls: ['./ehs-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsHeaderComponent {
  /**
   * The navigation data to display in the header
   */
  @Input() data?: EhsNavData[];

  /**
   * The action data to emit from the nested data
   */
  @Output() action = new EventEmitter<EhsNavActionData>();

  public get leftData(): EhsNavData[] {
    return (this.data || []).filter(isStartEhsNavData);
  }

  public get rightData(): EhsNavData[] {
    return (this.data || []).filter(isEndEhsNavData);
  }

  public get hasSplitData(): boolean {
    return !!(this.leftData.length && this.rightData.length);
  }
}
