import { Injectable } from '@angular/core';
import { AdminUserScopes, getId } from '@common';
import { UserActionColumn, UserColumn } from './user-table-column';

@Injectable({
  providedIn: 'root'
})
export class EhsUserTableService {
  getColumns(): UserColumn[] {
    return [
      {
        columnTitle: 'Type',
        prop: 'partial',
        width: '40px'
      },
      {
        columnTitle: 'Name',
        prop: 'user',
        width: '230px'
      },
      {
        columnTitle: 'Client Admin',
        prop: 'clientAdmin',
        width: '40px'
      },
      {
        columnTitle: 'Company',
        prop: 'company',
        width: '230px'
      },
      {
        columnTitle: 'DOB',
        prop: 'birthDay',
        width: '120px'
      },
      {
        columnTitle: 'SSN',
        prop: 'ssn',
        width: '100px'
      },
      {
        columnTitle: 'Username',
        prop: 'username',
        width: '180px'
      },
      {
        columnTitle: 'Phone',
        prop: 'phone',
        width: '180px'
      },
      {
        columnTitle: 'Email',
        prop: 'email',
        width: '400px'
      },
      {
        columnTitle: 'Short ID',
        prop: 'shortId',
        width: '150px'
      }
    ];
  }

  getActionColumns(scopes: AdminUserScopes[]): UserActionColumn[] {
    return (
      (
        [
          {
            type: AdminUserScopes.USER_LOGIN,
            scope: AdminUserScopes.USER_LOGIN,
            name: 'Login As User',
            icon: 'login'
          },
          {
            type: AdminUserScopes.PASSWORD_RESET,
            scope: AdminUserScopes.PASSWORD_RESET,
            name: 'Reset Password',
            icon: 'lock_reset'
          },
          {
            type: 'sendResetPassword',
            scope: AdminUserScopes.PASSWORD_RESET,
            name: 'Send Reset Password Email',
            icon: 'mail_lock'
          },
          {
            type: AdminUserScopes.MFA_DELETE,
            scope: AdminUserScopes.MFA_DELETE,
            name: 'Remove MFA',
            icon: 'phonelink_lock',
            displayFn: ({ user }) =>
              user.hasMFA && scopes.includes(AdminUserScopes.MFA_DELETE)
          },
          {
            type: AdminUserScopes.MFA_DISABLE,
            scope: AdminUserScopes.MFA_DISABLE,
            name: 'Disable MFA',
            icon: 'no_cell',
            displayFn: ({ user }) =>
              !user.hasMFA &&
              !user.hasMFADisabled &&
              scopes.includes(AdminUserScopes.MFA_DISABLE)
          },
          {
            type: 'enableMFA',
            scope: AdminUserScopes.MFA_DISABLE,
            name: 'Enable MFA',
            icon: 'smartphone',
            displayFn: ({ user }) =>
              user.hasMFADisabled &&
              scopes.includes(AdminUserScopes.MFA_DISABLE)
          },
          {
            type: AdminUserScopes.UPDATE_PROFILE,
            scope: AdminUserScopes.UPDATE_PROFILE,
            name: 'Edit Profile',
            icon: 'manage_accounts'
          },
          {
            type: AdminUserScopes.VERIFY_EMAIL,
            scope: AdminUserScopes.VERIFY_EMAIL,
            name: 'Verify Email',
            icon: 'task_alt'
            // **Note** this can't be checked, as its on
            // the firebase user, not the normal user
          },
          {
            type: AdminUserScopes.UNLOCK_ACCOUNT,
            scope: AdminUserScopes.UNLOCK_ACCOUNT,
            name: 'Unlock Account',
            icon: 'lock_open',
            // Only shown if the user is locked
            displayFn: ({ user }) => user.locked
          },
          {
            type: AdminUserScopes.TEMPORARY_ACCESS,
            scope: AdminUserScopes.TEMPORARY_ACCESS,
            name: 'Temporary Access',
            icon: 'timer'
          },
          {
            type: AdminUserScopes.MOVE_USER_COMPANY,
            scope: AdminUserScopes.MOVE_USER_COMPANY,
            name: 'Move user to New Company',
            icon: 'transfer_within_a_station'
          },
          {
            type: AdminUserScopes.DESYNC_USER_DATA,
            scope: AdminUserScopes.DESYNC_USER_DATA,
            name: 'De-syncs the users previous company data',
            displayFn: ({ user }) => !!user.prevCompanies?.length
          },
          {
            type: AdminUserScopes.REMOVE_USER,
            scope: AdminUserScopes.REMOVE_USER,
            name: 'Remove User',
            icon: 'person_remove_alt_1',
            // Only shown if the user is flagged
            // **or** if the admin has permissions to remove.
            displayFn: ({ user }) =>
              user.flaggedForDeletion ||
              scopes.includes(AdminUserScopes.REMOVE_USER)
          },
          {
            type: AdminUserScopes.CLIENT_PROMOTION,
            scope: AdminUserScopes.CLIENT_PROMOTION,
            name: 'Promote',
            icon: 'security',
            displayFn: ({ user }) => !user.clientAdmin
          },
          {
            type: 'demoteUser',
            scope: AdminUserScopes.CLIENT_PROMOTION,
            name: 'Demote',
            icon: 'remove_moderator',
            displayFn: ({ user }) => user.clientAdmin
          },
          {
            type: 'register',
            scope: AdminUserScopes.UPDATE_REG,
            name: 'Register',
            icon: 'add'
          },
          {
            type: 'flagRemoval',
            scope: AdminUserScopes.FLAG_REMOVE_USER,
            name: 'Flag Removal',
            icon: 'flag',
            // Only show if the user is not flagged
            // **and** the user doesn't have remove permissions
            // as then they can remove them directly
            displayFn: ({ user }) =>
              !user.flaggedForDeletion &&
              !scopes.includes(AdminUserScopes.REMOVE_USER)
          },
          {
            type: 'unflagRemoval',
            scope: AdminUserScopes.FLAG_REMOVE_USER,
            name: 'Unflag Removal',
            icon: 'outlined_flag',
            // Only show if the user is not flagged
            // **and** the user doesn't have remove permissions
            // as then they can remove them directly
            displayFn: ({ user }) =>
              !user.flaggedForDeletion &&
              !scopes.includes(AdminUserScopes.REMOVE_USER)
          },
          {
            type: 'addToMerge',
            scope: AdminUserScopes.MERGE_USER,
            name: 'Merge User',
            icon: 'merge',
            displayFn: ({ user, usersToMerge }) => {
              if (!usersToMerge) {
                return true;
              }

              if (
                usersToMerge.length < 2 &&
                !usersToMerge.includes(getId(user))
              ) {
                return true;
              }

              return false;
            }
          },
          {
            type: 'removeFromMerge',
            scope: AdminUserScopes.MERGE_USER,
            name: 'Remove From Merge',
            icon: 'merge_type',
            displayFn: ({ user, usersToMerge }) =>
              usersToMerge && usersToMerge.includes(getId(user))
          },
          {
            type: 'restrictData',
            scope: AdminUserScopes.RESTRICT_USER_DATA,
            name: 'Restrict Data',
            icon: 'do_not_disturb'
          },
          {
            type: 'view',
            name: 'View',
            icon: 'account_circle'
          }
          // Not sure why I need to cast here, but it would h ave something to do
          // with the `type` property it seems
        ] as Array<UserActionColumn>
      )
        // If no scopes are provided at all, then we consider it "unscoped", and
        // thus let through
        .filter(({ scope }) => (scope ? scopes.includes(scope) : true))
    );
  }
}
