import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserRegistration, deepClone } from '@common';

@Component({
  selector: 'ehs-self-report-correction',
  templateUrl: './ehs-self-report-correction.component.html',
  styleUrls: ['./ehs-self-report-correction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  standalone: false
})
export class EhsSelfReportCorrectionComponent {
  @Input() set userRegistration(userRegistration: UserRegistration) {
    if (!userRegistration) {
      return;
    }
    this._userRegistration = deepClone(userRegistration);

    this.declined =
      this._userRegistration.declined || ({} as UserRegistration['declined']);
  }

  public get userRegistration() {
    return this._userRegistration;
  }

  @Output() updateRegistration = new EventEmitter<UserRegistration>();

  public _userRegistration: UserRegistration;
  public declined: UserRegistration['declined'];

  constructor(
    // Angular Material
    private snackBar: MatSnackBar
  ) {}

  /**
   * If a form-group is marked as declined we want to clear that value.
   * ? Not sure if there is a better way to do this
   */
  public toggleDeclined(input: keyof UserRegistration['declined']) {
    this.declined[input] = !this.declined[input];

    if (input === 'bloodPressure' && this.declined.bloodPressure) {
      this.userRegistration.bloodPressure = {};
    } else if (input === 'height' && this.declined.height) {
      this.userRegistration.height = {};
    } else if (input === 'tobacco' && this.declined.tobacco) {
      this.userRegistration.usesTobacco = null;
    } else if (this.declined[input]) {
      this.userRegistration[input] = null;
    }
    this.userRegistration.declined = this.declined;
  }

  public handleInputChange(field: NgModel) {
    switch (field.name) {
      case 'bloodPressureDiastolic':
        this.userRegistration.bloodPressure = {
          ...(this.userRegistration.bloodPressure || {}),

          diastolic: field.value
        };
        this.updateRegistration.emit(this.userRegistration);
        break;
      case 'bloodPressureSystolic':
        this.userRegistration.bloodPressure = {
          ...(this.userRegistration.bloodPressure || {}),
          systolic: field.value
        };
        this.updateRegistration.emit(this.userRegistration);
        break;
      case 'heightFeet':
        this.userRegistration.height = {
          ...(this.userRegistration.height || {}),

          feet: field.value
        };
        this.updateRegistration.emit(this.userRegistration);
        break;
      case 'heightInches':
        this.userRegistration.height = {
          ...(this.userRegistration.height || {}),
          inches: field.value
        };

        this.updateRegistration.emit(this.userRegistration);
        break;

      default:
        break;
    }
  }
}
