import { Pipe, PipeTransform } from '@angular/core';
import { toNameCase } from '@common';

@Pipe({
  name: 'namecase',
  standalone: false
})
export class NameCasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    } else {
      return toNameCase(value);
    }
  }
}
