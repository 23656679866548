import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EhsConfirmDialogData } from './ehs-confirm-dialog-data';

@Component({
  selector: 'ehs-confirm-dialog',
  templateUrl: './ehs-confirm-dialog.component.html',
  styleUrls: ['./ehs-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsConfirmDialogComponent {
  checkBoxValue?: boolean;
  public get hasMessages(): boolean {
    return Array.isArray(this.data.message);
  }

  public hasImage = !!this.data.imageSrc;

  constructor(@Inject(MAT_DIALOG_DATA) public data: EhsConfirmDialogData) {
    this.checkBoxValue = data.checkBox?.initialValue;
  }
}
