import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import {
  ErrorHandler,
  NgModule,
  inject,
  provideAppInitializer
} from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions
} from '@angular/material/form-field';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarConfig,
  MatSnackBarModule
} from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import {
  EHS_BASE_URL_INJECTION_TOKEN,
  EHS_UI_FEATURE_FLAGS_INJECTION_TOKEN
} from '@ehs-ui';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { ScriptLoaderModule } from '@script-loader';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha-2';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { appInitFactory } from './core/app-init-factory';
import { AdminCoreStoreModule } from './core/app-store/admin-core/admin-core-store.module';
import { AppStoreModule } from './core/app-store/app-store.module';
import { AuthFacade } from './core/app-store/auth/auth.facade';
import { GlobalErrorHandler } from './core/global-error-handler';
import { HttpLoadingInterceptor } from './core/interceptors/http-loading.interceptor';
import { HttpRetryInterceptor } from './core/interceptors/http-retry.interceptor';
import { HttpUnauthInterceptor } from './core/interceptors/http-unauth.interceptor';
import { KeepAliveInterceptor } from './core/interceptors/keep-alive.interceptor';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // Ngrx
    AppStoreModule,
    AdminCoreStoreModule, // This is required for the unauth interceptor
    StoreRouterConnectingModule.forRoot(),
    // Core "root" modules
    ScriptLoaderModule.forRoot({
      scripts: [
        // {
        //   src: `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}`,
        //   async: true
        // },
        // Only add this if given
        ...(environment.pingdom
          ? [
              {
                src: `https://rum-static.pingdom.net/${environment.pingdom}`,
                async: true
              }
            ]
          : [])
      ]
    }),
    // Angular Material
    MatNativeDateModule,
    MatDialogModule,
    MatSnackBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    // GoogleAnalyticsService,
    provideAppInitializer(() => {
      const initializerFn = appInitFactory(inject(AuthFacade));

      return initializerFn();
    }),
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.reCaptchaKey
    },
    // Http interceptors
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: HttpLoadingInterceptor
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: HttpRetryInterceptor
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: HttpUnauthInterceptor
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: KeepAliveInterceptor
    },
    // EHS ui injections
    {
      provide: EHS_BASE_URL_INJECTION_TOKEN,
      multi: true,
      useValue: environment.baseUrl
    },
    {
      provide: EHS_UI_FEATURE_FLAGS_INJECTION_TOKEN,
      useValue: {
        showAnimations: environment.showAnimations,
        showResultedButton: environment.showResultedButton,
        showQATools: environment.showQATools
      }
    },
    // Angular material global defaults
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline'
      } as MatFormFieldDefaultOptions
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 2000
      } as MatSnackBarConfig
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
  // Removed Analytics ID - See Issue #4049
  // **Note** inject this so it automatically runs
  // constructor(private googleAnalyticsService: GoogleAnalyticsService) {}
}
