import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonResponse } from '@common';

/**
 * Service used to call other routes or custom debugging
 * routes to help with QA-ing different processes
 */
@Injectable({
  providedIn: 'root'
})
export class AdminDebugHttpService {
  private adminUrl = 'api/v1/admin';
  private cronUrl = 'api/v1/cron';

  constructor(private http: HttpClient) {}

  public sendResultsReadySms(params: { userId: string }) {
    const { userId } = params;

    return this.http.put<CommonResponse>(`${this.adminUrl}/sms/results-ready`, {
      params: { userId }
    });
  }

  public sendIncentiveReminderEmail(params: { eventServiceId: string }) {
    const { eventServiceId } = params;

    return this.http.get<CommonResponse>(`${this.cronUrl}/incentive`, {
      params: new HttpParams({
        fromObject: {
          debugEventServiceId: eventServiceId
        }
      })
    });
  }
}
