import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Optional,
  Output
} from '@angular/core';
import {
  EventService,
  EventServiceUtil,
  getId,
  OffsiteService,
  OnsiteRegistrationType,
  OnsiteService,
  ServiceType,
  UserTestUtil
} from '@common';
import { EHS_SHOW_COMPANY_INJECTION_TOKEN } from '../../shared/ehs-show-company-injection-token';
import {
  CombinedServiceType,
  RoutedCombinedServiceType
} from '../ehs-service-types/ehs-service-types.component';

@Component({
  selector: 'ehs-gwapps-service',
  templateUrl: './ehs-gwapps-service.component.html',
  styleUrls: ['./ehs-gwapps-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsGwappsServiceComponent {
  /**
   * The relevant event-service we are displaying data for
   */
  @Input() eventService?: EventService;
  /**
   * Event emitted when the user clicks on the view button
   */
  @Output() view = new EventEmitter<EventService>();
  /**
   * Event emitted when the user selects the validate button
   */
  @Output() validate = new EventEmitter<EventService>();
  /** Flag passed when called from client-admin, used for routes */
  @Input() clientAdmin?: boolean;

  @Output() createNotification = new EventEmitter<EventService>();

  /**
   * The service-types for this event-service.
   * Will return an empty array if no event-service is available.
   *
   * These are automatically converted to route varients.
   */
  public get serviceTypes(): Array<
    CombinedServiceType | RoutedCombinedServiceType
  > {
    return this.eventService
      ? EventServiceUtil.getServiceTypes({
          eventService: this.eventService,
          includeOnsiteTypes: true
        }).map((serviceType) => {
          if (
            serviceType === ServiceType.PROVIDER_HEALTH &&
            this.eventService
          ) {
            return {
              serviceType,
              route: [
                `/ehs/${this.clientAdmin ? 'client' : 'admin'}/company`,
                getId(this.eventService.company),
                'service',
                getId(this.eventService),
                'provider'
              ]
            } as RoutedCombinedServiceType;
          }

          if (serviceType === ServiceType.OFFSITE && this.eventService) {
            return {
              serviceType,
              route: [
                `/ehs/${this.clientAdmin ? 'client' : 'admin'}/company`,
                getId(this.eventService.company),
                'service',
                getId(this.eventService),
                'offsite'
              ]
            } as RoutedCombinedServiceType;
          }

          if (serviceType === ServiceType.ONSITE && this.eventService) {
            return {
              serviceType,
              route: [
                `/ehs/${this.clientAdmin ? 'client' : 'admin'}/company`,
                getId(this.eventService.company),
                'service',
                getId(this.eventService),
                'event'
              ]
            } as RoutedCombinedServiceType;
          }

          if (
            [
              OnsiteRegistrationType.SCREENING,
              OnsiteRegistrationType.FLU_VACCINE,
              OnsiteRegistrationType.COVID_VACCINE
            ].includes(serviceType as OnsiteRegistrationType)
          ) {
            return {
              serviceType,
              route: [
                `/ehs/${this.clientAdmin ? 'client' : 'admin'}/company`,
                getId(this.eventService.company),
                'service',
                getId(this.eventService),
                'event'
              ]
            };
          }

          return serviceType;
        })
      : [];
  }

  public get hasOffsiteScreening(): boolean {
    return this.serviceTypes
      .map((serviceType) =>
        typeof serviceType === 'string' ? serviceType : serviceType.serviceType
      )
      .includes(ServiceType.OFFSITE);
  }

  public get hasOnsiteScreening(): boolean {
    return this.serviceTypes
      .map((serviceType) =>
        typeof serviceType === 'string' ? serviceType : serviceType.serviceType
      )
      .includes(OnsiteRegistrationType.SCREENING);
  }

  /**
   * Returns the onsite lab, if there is one
   */
  public get onsiteLab(): string {
    const labType =
      // None could be passed, but this would be transformed to ???
      (
        EventServiceUtil.getSelectedService({
          eventService: this.eventService,
          serviceType: ServiceType.ONSITE
        }) as OnsiteService
      )?.labType;

    if (labType === 'none') {
      return 'none';
    }

    return (
      UserTestUtil.getLabName(labType, {
        includeAlts: true
      }) || '???'
    );
  }

  /**
   * Returns the offsite lab, there should alway sbe one
   */
  public get offsiteLab(): string {
    return (
      UserTestUtil.getLabName(
        (
          EventServiceUtil.getSelectedService({
            eventService: this.eventService,
            serviceType: ServiceType.OFFSITE
          }) as OffsiteService
        )?.labType,
        {
          // Include EHS lab
          includeAlts: true
        }
      ) || '???'
    );
  }

  /**
   * Has any kind of screening, onsite or offsite.
   */
  public get hasScreening(): boolean {
    return this.hasOffsiteScreening || this.hasOnsiteScreening;
  }

  public get companyUrl(): string {
    return `/ehs/${this.clientAdmin ? 'client' : 'admin'}/company/${getId(
      this.eventService?.company
    )}`;
  }

  public get eventServiceUrl(): string {
    return `/ehs/${this.clientAdmin ? 'client' : 'admin'}/company/${getId(
      this.eventService?.company
    )}/service/${getId(this.eventService)}`;
  }

  public get canViewEvents(): boolean {
    return this.serviceTypes.includes(ServiceType.ONSITE);
  }

  public get eventUrl(): string {
    return `/ehs/${this.clientAdmin ? 'client' : 'admin'}/company/${getId(
      this.eventService?.company
    )}/event?eventService=${getId(this.eventService)}`;
  }

  public sendNotification() {
    this.createNotification.emit(this.eventService);
  }

  constructor(
    @Inject(EHS_SHOW_COMPANY_INJECTION_TOKEN)
    @Optional()
    public showCompany?: boolean
  ) {}
}
