import {
  AdminUserSearchForm,
  CommonResponse,
  OnsiteRegistrationType,
  OnsiteUserRegistration,
  PartialUser,
  UniqueUser,
  UserWithRegistrations,
  User,
  SmsNotification
} from '@common';
import {
  entityHttpActionFactory,
  entitySelectionActionFactory
} from '@ehs-ngrx/common';
import { createAction, props } from '@ngrx/store';

export const userNgrxActions = {
  // **Note** this might be changed/updated with upsertOne
  set: createAction(
    '[USER_NGRX] SET',
    props<{
      entity: User | UserWithRegistrations;
    }>()
  ),

  setMany: createAction(
    '[USER_NGRX] SET_MANY',
    props<{ entities: (User | UserWithRegistrations)[] }>()
  ),

  upsertOne: createAction(
    '[USER_NGRX] UPSERT_ONE',
    props<{ entity: User | UserWithRegistrations }>()
  ),

  updateUser: entityHttpActionFactory<
    Partial<User>,
    {
      entity: User;
    }
  >('[USER_NGRX] UPDATE_USER'),

  list: entityHttpActionFactory<
    AdminUserSearchForm,
    { users: (User | UserWithRegistrations)[]; hasNext: boolean }
  >('[USER_NGRX] LIST'),

  clearUsers: createAction('[USER_NGRX] CLEAR_USERS'),

  setPageNumber: createAction(
    '[USER_NGRX] SET_PAGE_NUMBER',
    props<{ pageNumber: number }>()
  ),

  loadMore: createAction(
    '[USER_NGRX] LOAD_MORE',
    props<{ payload: AdminUserSearchForm }>()
  ),

  get: entityHttpActionFactory<
    {
      id: string;
    },
    {
      entity: User;
    }
  >('[USER_NGRX] GET'),

  getUserWithUnique: entityHttpActionFactory<
    UniqueUser,
    {
      entity: User;
    },
    CommonResponse
  >('[USER_NGRX] GET_USER_WITH_UNIQUE'),

  createWalkin: entityHttpActionFactory<
    {
      user: PartialUser;
      eventLocation: string;
      groupType?: number;
      onsiteTypes: OnsiteRegistrationType[];
    },
    {
      user: User;
      userRegistration: OnsiteUserRegistration;
    },
    { error: CommonResponse }
  >('[USER_NGRX] CREATE_WALKIN'),

  createAdditionalWalkin: entityHttpActionFactory<
    {
      user: PartialUser;
      eventLocation: string;
      groupType?: number;
      onsiteTypes: OnsiteRegistrationType[];
      isAdditional: boolean;
    },
    {
      user: User;
      userRegistration: OnsiteUserRegistration;
    },
    { error: CommonResponse }
  >('[USER_NGRX] CREATE_ADDITIONAL_WALKIN'),

  loginAsUser: entityHttpActionFactory<
    { userId: string; url?: string },
    { user: User },
    { error: CommonResponse }
  >('[USER_NGRX] LOGIN_AS_USER'),

  resetPassword: entityHttpActionFactory<
    { user: User },
    CommonResponse,
    { error: CommonResponse }
  >('[USER_NGRX] RESET_PASSWORD'),

  setTemporaryAccess: entityHttpActionFactory<
    { user: User },
    { user: User },
    { error: CommonResponse }
  >('[USER_NGRX] SET_TEMPORARY_ACCESS'),

  passwordResetEmail: entityHttpActionFactory<
    { user: User },
    CommonResponse,
    { error: CommonResponse }
  >('[USER_NGRX] RESET_PASSWORD_EMAIL'),

  removeMFA: entityHttpActionFactory<
    { user: User },
    { user: User },
    { error: CommonResponse }
  >('[USER_NGRX] REMOVE_MFA'),

  disableMFA: entityHttpActionFactory<
    { user: User },
    { user: User },
    { error: CommonResponse }
  >('[USER_NGRX] DISABLE_MFA'),

  enableMFA: entityHttpActionFactory<
    { user: User },
    { user: User },
    { error: CommonResponse }
  >('[USER_NGRX] ENABLE_MFA'),

  verifyEmail: entityHttpActionFactory<
    { user: User },
    undefined,
    { error: CommonResponse }
  >('[USER_NGRX] VERIFY_EMAIL'),

  unlockAccount: entityHttpActionFactory<
    { user: User },
    { user: User },
    { error: CommonResponse }
  >('[USER_NGRX] UNLOCK_ACCOUNT'),

  moveUserCompany: entityHttpActionFactory<
    { user: User },
    { user: User },
    { error: CommonResponse }
  >('[USER_NGRX] MOVE_USER_COMPANY'),

  removeUser: entityHttpActionFactory<
    { user: User },
    { user: User },
    { error: CommonResponse }
  >('[USER_NGRX] REMOVE_USER'),

  flagRemoveUser: entityHttpActionFactory<
    { user: User },
    { user: User },
    { error: CommonResponse }
  >('[USER_NGRX] FLAG_REMOVE_USER'),

  mergeUsers: entityHttpActionFactory<
    undefined,
    {
      mergedUser: User;
      userRemoved: User | PartialUser;
    }
  >('[USER_NGRX] MERGE_USERS'),

  editUser: entityHttpActionFactory<
    {
      user: User;
    },
    {
      user: User;
    },
    { error: CommonResponse }
  >('[USER_NGRX] EDIT_USER'),

  restrictUserData: entityHttpActionFactory<
    { user: User },
    { user: User },
    { error: CommonResponse }
  >('[USER_NGRX] RESTRICT_USER_DATA'),

  promoteUser: entityHttpActionFactory<
    {
      user: User;
    },
    {
      user: User;
    },
    { error: CommonResponse }
  >('[USER_NGRX] PROMOTE_USER'),

  demoteUser: entityHttpActionFactory<
    {
      user: User;
    },
    {
      user: User;
    },
    { error: CommonResponse }
  >('[USER_NGRX] DEMOTE_USER'),

  desyncUser: entityHttpActionFactory<
    {
      user: string;
    },
    {
      user: User;
    }
  >('[USER_NGRX] DESYNC_USER'),

  createPartialUser: entityHttpActionFactory<
    {
      user: Partial<PartialUser>;
    },
    {
      user: PartialUser;
    }
  >('[USER_NGRX] CREATE_PARTIAL_USER'),

  sendSmsMessage: entityHttpActionFactory<
    {
      message: SmsNotification;
    },
    {
      message: SmsNotification;
    }
  >('[USER_NGRX] SEND_SMS_MESSAGE'),

  ...entitySelectionActionFactory<string, 'USER_NGRX'>('USER_NGRX')
};
