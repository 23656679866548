import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  EventService,
  EventServiceStats,
  EventServiceUtil,
  getId,
  isProviderHealthService,
  ProviderHealthService,
  ServiceType
} from '@common';

@Component({
  selector: 'ehs-gwapps-provider-health-forms',
  templateUrl: './ehs-gwapps-provider-health-forms.component.html',
  styleUrls: ['./ehs-gwapps-provider-health-forms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsGwappsProviderHealthFormsComponent {
  /**
   * The relevant event-service we are displaying data for
   */
  @Input() eventService?: EventService | null;

  @Input() eventServiceStats?: EventServiceStats | null;

  /** Flag passed when called from client-admin, used for routes */
  @Input() clientAdmin?: boolean;

  public get providerHealthService(): ProviderHealthService | undefined {
    const selectedService = EventServiceUtil.getSelectedService({
      serviceType: ServiceType.PROVIDER_HEALTH,
      eventService: this.eventService
    });

    if (isProviderHealthService(selectedService)) {
      return selectedService;
    }

    return undefined;
  }
  /**
   * The service-types for this event-service.
   * Will return an empty array if no event-service is available.
   */

  public get companyUrl(): string {
    return `/ehs/${this.clientAdmin ? 'client' : 'admin'}/company/${getId(
      this.eventService?.company
    )}`;
  }

  public get eventServiceUrl(): string {
    return `/ehs/${this.clientAdmin ? 'client' : 'admin'}/company/${getId(
      this.eventService?.company
    )}/service/${getId(this.eventService)}/provider`;
  }
}
