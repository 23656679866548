import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { UserEmailAudit, USER_EMAIL_AUDIT_TYPES } from '@email-types';
import {
  UserEmailAuditListActionType,
  UserEmailAuditListActionColumn
} from './user-email-audit-list-table-column';
import {
  actionColumnsMap,
  displayedColumnsMap
} from './ehs-user-email-audit-list-constants';
import { MatChipListboxChange } from '@angular/material/chips';

@Component({
  selector: 'ehs-user-email-audit-list',
  templateUrl: './ehs-user-email-audit-list.component.html',
  styleUrls: ['./ehs-user-email-audit-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsUserEmailAuditListComponent {
  @Input() userEmailAudits: UserEmailAudit[];
  // Current type filter for the audits
  @Input() templateType: USER_EMAIL_AUDIT_TYPES | 'all';

  @Input() hasNext: boolean;

  @Input() pageNumber: number;

  @Input() currentPageNumber: number;

  @Input() loading: boolean;

  @Output() actionChange = new EventEmitter<{
    actionType: UserEmailAuditListActionType;
    userEmailAudit: UserEmailAudit;
  }>();

  @Output() templateChange = new EventEmitter<{
    template: USER_EMAIL_AUDIT_TYPES | 'all';
  }>();

  @Output() loadMore = new EventEmitter();

  @Output() changeRenderedPage = new EventEmitter<{
    currentPageNumber: number;
  }>();

  public displayedColumns = displayedColumnsMap.all;

  public trackByFn(index: number) {
    return index;
  }

  public changePageNumber(currentPageNumber: number) {
    this.changeRenderedPage.emit({ currentPageNumber });
  }

  public onClick(params: {
    actionType: UserEmailAuditListActionType;
    userEmailAudit: UserEmailAudit;
  }) {
    const { actionType, userEmailAudit } = params;

    this.actionChange.emit({ actionType, userEmailAudit });
  }

  /**
   * Returns list of actions for menu attached to
   * a corresponding type of user audit
   */
  public getUserEmailAuditSpecificActions(
    userEmailAudit: UserEmailAudit
  ): UserEmailAuditListActionColumn[] {
    return actionColumnsMap[userEmailAudit.emailTemplate];
  }

  public onTemplateChange(change: MatChipListboxChange) {
    this.displayedColumns =
      displayedColumnsMap[change.value as USER_EMAIL_AUDIT_TYPES | 'all'];

    this.templateChange.emit({
      template: change.value as USER_EMAIL_AUDIT_TYPES | 'all'
    });
  }

  public onNext() {
    if (this.pageNumber - this.currentPageNumber === 0) {
      this.loadMore.emit();

      return;
    }

    this.changeRenderedPage.emit({
      currentPageNumber: this.currentPageNumber + 1
    });
  }
}
