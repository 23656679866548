import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  EventLocation,
  EventService,
  getId,
  InsuranceProvider,
  OnsiteUserRegistration,
  PhoneUtil,
  toMap,
  User,
  UserInsurance,
  UserTest,
  UserTestId
} from '@common';
import { PreBioReqImages } from '@pre-bio-req-pdf';
import { combineLatest, forkJoin, from, Observable } from 'rxjs';
import { map, mergeMap, shareReplay, take, tap } from 'rxjs/operators';
import { EHS_BASE_URL_INJECTION_TOKEN } from '../../shared/ehs-base-url-injection-token';

interface CommonParams {
  user: User;
  /**
   * The event-location the event is at.
   */
  eventLocation: EventLocation;
  /**
   * The event-service the user-registration is tied too
   */
  eventService: EventService;
  /**
   * All insurance providers, used for rendering
   */
  insuranceProviders: InsuranceProvider[];
  /**
   * All user-tests, either in map or record form
   */
  userTests: UserTest[] | Record<UserTestId, UserTest>;
}

/**
 * This service managers the rendering of the `pre-bio-req-pdf`,
 * for local download, or merging using the merge-pdf service.
 *
 * **note** This is specifically to render the bio screening onsite
 * using an external lab.
 */
@Injectable()
export class EhsBioReqService {
  public images$: Observable<PreBioReqImages>;
  constructor(
    @Inject(EHS_BASE_URL_INJECTION_TOKEN) private baseUrl: string,
    private http: HttpClient
  ) {
    this.images$ = this.getImages$();
  }

  private getImages$(): Observable<PreBioReqImages> {
    const getPath = (filename: string) => `assets/img/${filename}`;

    return forkJoin(
      // Load all fingerstick PDF related images
      ['ehs_logo.png', 'bio-req-pdf/bio-staff-only.jpg'].map((img) =>
        this.http.get(getPath(img), { responseType: 'blob' })
      )
    ).pipe(
      // Convert every image to base64
      mergeMap((blobs) =>
        forkJoin(
          blobs.map((blob) => {
            const reader = new FileReader();

            reader.readAsDataURL(blob);

            return new Promise<string>(
              (resolve) =>
                (reader.onloadend = () => resolve(reader.result as string))
            );
          })
        )
      ),
      map(
        ([logo, staffSection]) =>
          ({
            logo,
            staffSection
          }) as PreBioReqImages
      ),
      take(1),
      // Only execute this 1 time, the first time.
      shareReplay(1)
    );
  }

  /**
   * Generates the biometric screening req,
   * where we return a buffer of the final pdf.
   */
  public generate(
    params: CommonParams & { userRegistration: OnsiteUserRegistration }
  ): Observable<Buffer> {
    const {
      user,
      userRegistration,
      eventLocation,
      eventService,
      userTests,
      insuranceProviders
    } = params;

    return combineLatest([
      from(import('@pre-bio-req-pdf')),
      this.images$,
      from(import('pdfmake/build/pdfmake')),
      from(import('pdfmake/build/vfs_fonts'))
    ]).pipe(
      take(1),
      tap(([, , { default: pdfMake }, { default: pdfFonts }]) =>
        (<any>pdfMake).addVirtualFileSystem(pdfFonts)
      ),
      mergeMap(([{ generatePreBioReqPdf }, images, pdfMake]) =>
        generatePreBioReqPdf({
          images,
          baseUrl: this.baseUrl,
          event: eventLocation,
          insuranceProviders,
          userTestMap: toMap({
            key: '_id',
            entities: (Array.isArray(userTests)
              ? userTests
              : Object.values(userTests)) as Array<UserTest>
          }) as Record<UserTestId, UserTest>,
          eventService,
          userMap: {
            [getId(user)]: user as User & { insurance: UserInsurance }
          },
          userRegs: [{ ...userRegistration, user }]
        }).then((pdf) => pdfMake.createPdf(pdf))
      ),
      mergeMap(
        (pdf) => new Promise<Buffer>((resolve) => pdf.getBuffer(resolve))
      )
    );
  }

  /**
   * Generates and downloads the biometric screening requisition.
   * This is used for onsite events with an external lab.
   */
  public generateAndDownload(
    params: CommonParams & {
      userRegistration: OnsiteUserRegistration | OnsiteUserRegistration[];
    }
  ): void {
    const {
      user,
      userRegistration,
      eventLocation,
      eventService,
      userTests,
      insuranceProviders
    } = params;

    combineLatest([
      from(import('@pre-bio-req-pdf')),
      this.images$,
      from(import('pdfmake/build/pdfmake')),
      from(import('pdfmake/build/vfs_fonts'))
    ])
      .pipe(
        take(1),
        tap(([, , { default: pdfMake }, { default: pdfFonts }]) =>
          (<any>pdfMake).addVirtualFileSystem(pdfFonts)
        ),
        take(1)
      )
      .subscribe(([{ generatePreBioReqPdf }, images, pdfMake]) =>
        generatePreBioReqPdf({
          images,
          baseUrl: this.baseUrl,
          event: eventLocation,
          insuranceProviders,
          userTestMap: toMap({
            key: '_id',
            entities: (Array.isArray(userTests)
              ? userTests
              : Object.values(userTests)) as Array<UserTest>
          }) as Record<UserTestId, UserTest>,
          eventService,
          userMap: {
            [getId(user)]: user as User & { insurance: UserInsurance }
          },
          userRegs: Array.isArray(userRegistration)
            ? userRegistration
            : [{ ...userRegistration, user }]
        }).then((pdfContent) =>
          pdfMake
            .createPdf(pdfContent)
            .download(`bio-req-${new Date().getTime()}.pdf`)
        )
      );
  }

  public generateAndOpen(
    params: CommonParams & {
      userRegistration: OnsiteUserRegistration | OnsiteUserRegistration[];
    }
  ): void {
    const {
      user,
      userRegistration,
      eventLocation,
      eventService,
      userTests,
      insuranceProviders
    } = params;

    combineLatest([
      from(import('@pre-bio-req-pdf')),
      this.images$,
      from(import('pdf-lib')),
      from(import('pdfmake/build/pdfmake')),
      from(import('pdfmake/build/vfs_fonts'))
    ])
      .pipe(
        take(1),
        mergeMap(
          ([
            { generatePreBioReqPdf },
            images,
            { PDFDocument },
            { default: pdfMake },
            { default: pdfFonts }
          ]) =>
            from(
              new Promise<Buffer>((resolve) => {
                (<any>pdfMake).addVirtualFileSystem(pdfFonts);

                generatePreBioReqPdf({
                  images,
                  baseUrl: this.baseUrl,
                  event: eventLocation,
                  insuranceProviders,
                  userTestMap: toMap({
                    key: '_id',
                    entities: (Array.isArray(userTests)
                      ? userTests
                      : Object.values(userTests)) as Array<UserTest>
                  }) as Record<UserTestId, UserTest>,
                  eventService,
                  userMap: {
                    [getId(user)]: user as User & { insurance: UserInsurance }
                  },
                  userRegs: Array.isArray(userRegistration)
                    ? userRegistration
                    : [{ ...userRegistration, user }]
                }).then((pdfContent) =>
                  pdfMake.createPdf(pdfContent).getBuffer(resolve)
                );
              })
            ).pipe(
              mergeMap(async (buffer) => PDFDocument.load(buffer)),
              mergeMap((pdf) => pdf.saveAsBase64())
            )
        )
      )
      .subscribe((base64) => PhoneUtil.openBase64Pdf(base64));
  }
}
