import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VerifyEmailDialogData } from './ehs-verify-email-dialog-data';
import { User } from '@common';

@Component({
  selector: 'ehs-verify-email-dialog',
  templateUrl: './ehs-verify-email-dialog.component.html',
  styleUrls: ['./ehs-verify-email-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class VerifyEmailDialogComponent implements OnInit {
  public user: User & { emailVerified: boolean };
  constructor(@Inject(MAT_DIALOG_DATA) private data: VerifyEmailDialogData) {}

  ngOnInit() {
    this.user = this.data.user;
  }
}
