/* eslint-disable @angular-eslint/no-input-rename */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  getId,
  ServiceType,
  toMap,
  UserRegistration,
  UserResult
} from '@common';
import {
  UserResultListActionColumn,
  UserResultListActionType
} from './user-result-list-table-column';

@Component({
  selector: 'ehs-ehs-user-result-list',
  templateUrl: './ehs-user-result-list.component.html',
  styleUrls: ['./ehs-user-result-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class EhsUserResultListComponent {
  public readonly displayedColumns = [
    'createdAt',
    'lab',
    'serviceType',
    'requisition',
    'hpn',
    'actions'
  ];

  /**
   * Component level feature flag. Pass true if we want to
   * display the three-dot-menu to the right of the edit button.
   *
   * This isn't available by default yet, as we need to setup everything
   * to support which actions can even be shown.
   */
  @Input() showMenu?: boolean;
  /**
   * The company-id, required for the edit-url.
   * If not given this wont be shown
   */
  @Input() companyId?: string;

  /**
   * The list of user-results to display within this list component.
   */
  @Input() userResults: UserResult[] = [];

  @Input() userResultListActions?: UserResultListActionColumn[] = [];

  @Input() actionLoading?: boolean;

  @Output() actionChange = new EventEmitter<{
    actionType: UserResultListActionType;
    userResult: UserResult;
  }>();

  /**
   * UserRegistrations of the list of user-registrations mapped to their _id.
   * The user-registration has most of the information, but could not be found
   */
  @Input('userRegistrations') set _userRegistrations(
    userRegistrations: UserRegistration[] | Record<string, UserRegistration>
  ) {
    this.userRegistrations = toMap({
      entities: Array.isArray(userRegistrations) ? userRegistrations : [],
      key: '_id'
    }) as Record<string, UserRegistration>;
  }

  public userRegistrations: Record<string, UserRegistration> = {};

  /**
   * Returns the user-registration for the user-result
   */
  public getUserRegistration(
    userResult: UserResult
  ): UserRegistration | undefined {
    return this.userRegistrations[getId(userResult.userRegistration)];
  }

  /**
   * Returns result short id as a string if available or
   * empty string otherwise.
   */
  public getRequisition(userResult: UserResult): string {
    const userReg = this.getUserRegistration(userResult);

    return userReg?.shortId || '';
  }

  /**
   * Returns list of actions for menu attached to individual
   * user results on the member details page.
   */
  public getUserResultSpecificActions(
    userResult: UserResult
  ): UserResultListActionColumn[] {
    return this.userResultListActions.filter((column) =>
      column.displayFn
        ? column.displayFn({
            userResult
          })
        : true
    );
  }

  /**
   * Returns the edit url with a redirect back to this page
   */
  public getEditUrl(userResult: UserResult) {
    return [
      `/ehs/admin/company/${this.companyId}/user/${getId(
        userResult.user
      )}/result/correction/${getId(userResult)}`
    ];
  }

  public trackByFn(index: number) {
    return index;
  }

  /**
   * Returns link to service event details taking into consideration
   * the service type.
   */
  public getViewLink(userResult: UserResult) {
    const userReg = this.getUserRegistration(userResult);
    const eventServiceId = getId(userReg?.eventService);

    if (!eventServiceId) {
      return [];
    }

    switch (userReg.serviceType) {
      case ServiceType.ONSITE:
        return [
          `/ehs/admin/company/${
            this.companyId
          }/service/${eventServiceId}/event/${getId(userReg?.eventLocation)}`
        ];
      case ServiceType.OFFSITE:
        return [
          `/ehs/admin/company/${this.companyId}/service/${eventServiceId}/offsite`
        ];
      case ServiceType.PROVIDER_HEALTH:
        return [
          `/ehs/admin/company/${this.companyId}/service/${eventServiceId}/provider`
        ];
      default:
        return [];
    }
  }
}
